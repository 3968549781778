import React from "react";
import "./TopBar.scss";

import LogoComp from "../../assets/logo/esitcvlogo.png";

import { Link, useNavigate } from "react-router-dom";
import {
  AppBar,
  Avatar,
  Button,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import { Grid } from "semantic-ui-react";
import { useSelector } from "react-redux";

export default function TopBar() {
  const user = useSelector((state) => state.auth);

  let navigation = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="relative"
      className="appBarComponent"
      style={{
        backgroundColor: "#f2f2f2",
      }}
    >
      <Toolbar className="appBarComponent">
        <Grid className="topBarLeft">
          <Typography variant="h6" noWrap component="a" href="/">
            <img src={LogoComp} alt="hamburger" className="esitcvlogo" />
          </Typography>

          <Typography variant="h6" component="div">
            <Link to="/work-search" exact>
              <h1 className="TitleHeader mainTitle">İş Bul</h1>
            </Link>
          </Typography>
          <Typography variant="h6" component="div">
            <Link to="/" exact>
              <h1 className="TitleHeader mainTitle">Kariyer Tavsiyesi</h1>
            </Link>
          </Typography>
        </Grid>

        <Grid className="topBarRight">
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, marginRight: 1 }}
          >
            Hoşgeldin, {user.user[0].name}
          </Typography>

          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <Avatar alt="Remy Sharp" src="/static/images/avatar/6.jpg" />
          </Button>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                navigation("/profile");
              }}
            >
              Profil
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                navigation("/settings");
              }}
            >
              Ayarlar
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                navigation("/signin");
              }}
            >
              Çıkış yap
            </MenuItem>
          </Menu>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
