import React, { useState } from "react";

// CSS
import "./BusinessPanelScreen.scss";

// Logo
import Logo from "../../assets/logo/esitcvlogo.png";

// Icons
import Advert from "../../assets/icons/advert.svg";
import AdvertAdd from "../../assets/icons/advertadd.svg";
import Candidates from "../../assets/icons/candidates.svg";
import Statistics from "../../assets/icons/statistics.svg";
import Settings from "../../assets/icons/setting.svg";
import Help from "../../assets/icons/help.svg";

//Components
import BusinessAdvert from "../../components/businessComponent/advert/BusinessAdvert";
import BusinessAdvertAdd from "../../components/businessComponent/advertAdd/BusinessAdvertAdd";
import BusinessCandidates from "../../components/businessComponent/candidates/BusinessCandidates";
import BusinessStatistics from "../../components/businessComponent/statistics/BusinessStatistics";

export default function BusinessPanelScreen() {
    const [screen, setScreen] = useState("business-advert");
    const [business, setMenu] = useState("0");
  return (
    <div className="BusinessPanel">
        <div className="BusinessMenu">
            <img className="esitCVLogo" src={Logo} alt="esitcv" />
            <div className="BusinessShortLine">
                <div className="BusinessLine"></div>
            </div>
            <div className="BusinessMenuButtons"
            onClick={() => {
                setScreen("business-advert");
              }}>
                <div
                onClick={() => {
                setMenu("0");
              }}
                className="BusinessButtons
                {business === '0' ? (
                    Active
                ) : (
                    console.log(business)
                )}
                ">
                    <h3>
                        <img className="BusinessPanelIcon" src={Advert} alt="Advert" />
                        <div className="PanelWriting">İlanlar</div>
                    </h3>
                </div>
            </div>
            <div className="BusinessMenuButtons"
            onClick={() => {
                setScreen("business-advertadd");
              }
            }>
                <div
                onClick={() => {
                setMenu("1");
              }}
                className="BusinessButton 
                {business === '1' ? (
                    Active
                ) : (
                    console.log(business)
                )}
                    ">
                    <h3>
                        <img className="BusinessPanelIcon" src={AdvertAdd} alt="Advert Add" />
                        <div className="PanelWriting">İlan Ekle</div>
                    </h3>
                </div>
            </div>
            <div className="BusinessMenuButtons"
            onClick={() => {
                setScreen("business-candidates");
              }}
              >
                <div
                onClick={() => {
                    setMenu("3");
                  }}
                className="BusinessButton
                {business === '3' ? (
                    Active
                ) : (
                    console.log(business)
                )}">
                    <h3>
                        <img className="BusinessPanelIcon" src={Candidates} alt="Candidates" />
                        <div className="PanelWriting">Adaylar</div>
                    </h3>
                </div>
            </div>
            <div className="BusinessMenuButtons"
           onClick={() => {
            setScreen("business-statistics");
          }}
           >
                <div
                onClick={() => {
                    setMenu("3");
                  }}
                className="BusinessButton
                {business === '4' ? (
                    Active
                ) : (
                    console.log(business)
                )}">
                    <h3>
                        <img className="BusinessPanelIcon" src={Statistics} alt="Statistics" />
                        <div className="PanelWriting">İstatistikler</div>
                    </h3>
                </div>
            </div>
            <div className="BusinessMenuButtonHelp">
                <div className="BusinessButtonHelp">
                    <h3>
                        <img className="BusinessPanelIcon" src={Help} alt="Help" />
                        <div className="PanelWriting">Yardım</div>
                    </h3>
                </div>
            </div>
            <div className="BusinessMenuButtonSettings">
                <div className="BusinessButtonHelp">
                    <h3>
                        <img className="BusinessPanelIcon" src={Settings} alt="Settings" />
                        <div className="PanelWriting">Ayarlar</div>
                    </h3>
                </div>
            </div>
        </div>
        {screen === "business-advert" ? (
            <BusinessAdvert />
          ) : screen === "business-advertadd" ? (
            <BusinessAdvertAdd />
          ) : screen === "business-candidates" ? (
            <BusinessCandidates />
          ) : screen === "business-statistics" ? (
            <BusinessStatistics/>                 
          ) : (
            console.log(screen)
          )}
    </div>
  );
}
