import {
  REGISTER_START,
  REGISTER_SUCCESS,
  REGISTER_FAILED,
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  CHANGE_PASSWORD_START,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILED,
} from "./types";

//config variables
const API_URL = "https://api.esitcv.com/";
const userURL = API_URL + "api/EmployeeAuth/";
const employerURL = API_URL + "api/EmployerAuth/";

//Kayıt ol
export const registerUser = (user, navigation) => {
  return async (dispatch) => {
    await dispatch({ type: REGISTER_START });
    await fetch(userURL + "register", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: user.name,
        surname: user.surname,
        email: user.email,
        password: user.password,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        if (json.resultStatus === 0) {
          dispatch({ type: REGISTER_SUCCESS });
          console.log("Kayıt başarılı");
          navigation("/signup/step-four");
        }
        if (json.resultStatus === 1) {
          console.log("Hata", json.data);
          dispatch({ type: REGISTER_FAILED, payload: json.data });
        }
        if (json.resultStatus === 2) {
          console.log("Hata2", json.data);
          dispatch({ type: REGISTER_FAILED, payload: json.data });
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: REGISTER_FAILED, payload: error });
      });
  };
};

export const registerEmployer = (user, navigation) => {
  return async (dispatch) => {
    await dispatch({ type: REGISTER_START });
    await fetch(employerURL + "register", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: user.email,
        password: user.password,
        companyName: user.companyName,
        province: user.province,
        district: user.district,
        taxOfficeProvince: user.taxAdministrationProvince,
        taxOfficeDistrict: user.taxAdministrationDistrict,
        taxNumber: user.taxNumber,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        if (json.resultStatus === 0) {
          dispatch({ type: REGISTER_SUCCESS });
          console.log("Kayıt başarılı");
          navigation("/signup/step-four");
        }
        if (json.resultStatus === 1) {
          console.log("Hata", json.data);
          dispatch({ type: REGISTER_FAILED });
        }
        if (json.resultStatus === 2) {
          console.log("Hata2", json.data);
          dispatch({ type: REGISTER_FAILED });
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: REGISTER_FAILED });
      });
  };
};

export const loginUser = (email, password, navigation) => {
  return async (dispatch) => {
    await dispatch({ type: LOGIN_START });
    await fetch(userURL + "login", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        console.log(json.data);
        if (json.resultStatus === 0) {
          dispatch({ type: LOGIN_SUCCESS, payload: [json.data] });
          console.log("Giriş başarılı");
          navigation("/");
        }
        if (json.resultStatus === 1) {
          console.log("Hata", json);
          dispatch({ type: LOGIN_FAILED, payload: json.data });
        }
        if (json.resultStatus === 2) {
          console.log("Hata2", json);
          dispatch({ type: LOGIN_FAILED, payload: json.data });
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: LOGIN_FAILED, payload: error });
      });
  };
};

export const loginEmployer = (email, password, navigation) => {
  return async (dispatch) => {
    await dispatch({ type: LOGIN_START });
    await fetch(employerURL + "login", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        console.log(json.data);
        if (json.resultStatus === 0) {
          dispatch({ type: LOGIN_SUCCESS, payload: [json.data] });
          console.log("Giriş başarılı");
          navigation("/business");
        }
        if (json.resultStatus === 1) {
          console.log("Hata", json);
          dispatch({ type: LOGIN_FAILED, payload: json.data });
        }
        if (json.resultStatus === 2) {
          console.log("Hata2", json);
          dispatch({ type: LOGIN_FAILED, payload: json.data });
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: LOGIN_FAILED, payload: error });
      });
  };
};

export const changePassword = (email, password, newPassword) => {
  return async (dispatch) => {
    await dispatch({ type: CHANGE_PASSWORD_START });
    await fetch(userURL + "changePassword", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
        newPassword: newPassword,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        if (json.resultStatus === 0) {
          dispatch({ type: CHANGE_PASSWORD_SUCCESS });
          console.log("Şifre değiştirme işlemi başarılı");
          return true;
        }
        if (json.resultStatus === 1) {
          console.log("Hata", json);
          dispatch({ type: CHANGE_PASSWORD_FAILED });
          return false;
        }
        if (json.resultStatus === 2) {
          console.log("Hata2", json);
          dispatch({ type: CHANGE_PASSWORD_FAILED });
          return false;
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: CHANGE_PASSWORD_FAILED });
      });
  };
};
