import React from "react";

//CSS
import "./BusinessAdvert.scss";

//Icons
import Views from "../../../assets/icons/eye-outline.svg";
import BusinessStroke from "../../../assets/icons/BusinessStroke.svg";

export default function BusinessAdvert() {
  return (
    <div className="BusinessMain">
            <div className="BusinessTopBar">
                <h1>İlanlar</h1>
            </div>
            <div className="BusinessMainPages">
                <div className="BusinessPages">
                    <div className="BusinessPanelAdvert">
                        <h3><a href="#">Aktif İlanlar</a><br/><div className="VerticalLineDark"/></h3>
                        <div className="VerticalLine"></div>
                        <h3><a href="#">Pasif İlanlar</a></h3>
                        <div className="VerticalLine"></div>
                        <h3><a href="#">Taslak İlanlar</a></h3>
                        <div className="VerticalLine"></div>
                        <h3><a href="#">Arşiv İlanlar</a></h3>
                    </div>
                    <div className="Clear"/>
                    <div className="BusinessPanelAdverts">
                        <div className="BusinessPanelAdvertNumber">
                            <h5>İlan No: 123456789</h5>
                        </div>
                        <div className="BusinessPanelAdvertTitle">
                            <h3>Satış Danışmanı</h3>
                            <img src={Views} className="BusinessPanelViews" alt="Views"/><h5>2000 Görüntülenme</h5>
                            <h7>298<br/>Cevaplanmayan</h7>
                            <h7>300<br/>Başvuru</h7>
                            <h7>1<br/>Favori Aday</h7>
                        </div>
                        <div className="BusinessPanelAdvertEdit">
                        <h3>İlanı Düzenle</h3><img src={BusinessStroke} className="" alt="Stroke"/>
                        </div>
                    </div>
                    <div className="BusinessPanelAdvertLine"/>
                    <div className="BusinessPanelAdverts">
                        <div className="BusinessPanelAdvertNumber">
                            <h5>İlan No: 123456789</h5>
                        </div>
                        <div className="BusinessPanelAdvertTitle">
                            <h3>Satış Danışmanı</h3>
                            <img src={Views} className="BusinessPanelViews" alt="Views"/><h5>2000 Görüntülenme</h5>
                            <h7>298<br/>Cevaplanmayan</h7>
                            <h7>300<br/>Başvuru</h7>
                            <h7>1<br/>Favori Aday</h7>
                        </div>
                        <div className="BusinessPanelAdvertEdit">
                        <h3>İlanı Düzenle</h3><img src={BusinessStroke} className="BusinessPanelStroke" alt="Stroke"/>
                        </div>
                    </div>
                    <div className="BusinessPanelAdvertLine"/>
                    <div className="BusinessPanelAdverts">
                        <div className="BusinessPanelAdvertNumber">
                            <h5>İlan No: 123456789</h5>
                        </div>
                        <div className="BusinessPanelAdvertTitle">
                            <h3>Satış Danışmanı</h3>
                            <img src={Views} className="BusinessPanelViews" alt="Views"/><h5>2000 Görüntülenme</h5>
                            <h7>298<br/>Cevaplanmayan</h7>
                            <h7>300<br/>Başvuru</h7>
                            <h7>1<br/>Favori Aday</h7>
                        </div>
                        <div className="BusinessPanelAdvertEdit">
                        <h3>İlanı Düzenle</h3><img src={BusinessStroke} className="BusinessPanelStroke" alt="Stroke"/>
                        </div>
                    </div>
                    <div className="BusinessPanelAdvertLine"/>
                    <div className="BusinessPanelAdverts">
                        <div className="BusinessPanelAdvertNumber">
                            <h5>İlan No: 123456789</h5>
                        </div>
                        <div className="BusinessPanelAdvertTitle">
                            <h3>Satış Danışmanı</h3>
                            <img src={Views} className="BusinessPanelViews" alt="Views"/><h5>2000 Görüntülenme</h5>
                            <h7>298<br/>Cevaplanmayan</h7>
                            <h7>300<br/>Başvuru</h7>
                            <h7>1<br/>Favori Aday</h7>
                        </div>
                        <div className="BusinessPanelAdvertEdit">
                        <h3>İlanı Düzenle</h3><img src={BusinessStroke} className="BusinessPanelStroke" alt="Stroke"/>
                        </div>
                    </div>
                    <div className="BusinessPanelAdvertLine"/>
                    <div className="BusinessPanelAdverts">
                        <div className="BusinessPanelAdvertNumber">
                            <h5>İlan No: 123456789</h5>
                        </div>
                        <div className="BusinessPanelAdvertTitle">
                            <h3>Satış Danışmanı</h3>
                            <img src={Views} className="BusinessPanelViews" alt="Views"/><h5>2000 Görüntülenme</h5>
                            <h7>298<br/>Cevaplanmayan</h7>
                            <h7>300<br/>Başvuru</h7>
                            <h7>1<br/>Favori Aday</h7>
                        </div>
                        <div className="BusinessPanelAdvertEdit">
                        <h3>İlanı Düzenle</h3><img src={BusinessStroke} className="BusinessPanelStroke" alt="Stroke"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  );
}
