import React, { useState, useEffect } from "react";
import "./SignUpStepTwo.scss";

//Components
import InputComponent from "../../../components/inputComponent/input";

//icons
import uploadPhotoImage from "../../../assets/signupImage/Unsplash.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function SignUpStepTwo() {
  const [nickname, setNickname] = useState("");
  const [bio, setBio] = useState("");

  const [errors, setErrors] = useState([]);

  const navigate = useLocation();
  const navigation = useNavigate();

  const validate = () => {
    let isValid = true;
    if (nickname.length < 3) {
      handleError("Kullanıcı adınız minimum 3 karakter olmalı", "nickname");
      isValid = false;
    }
    if (bio.length < 12) {
      handleError("Biografin 12 karakterden az olamaz", "bio");
      isValid = false;
    }

    if (isValid) {
      register();
      console.log("basarılı");
    }
  };

  const handleError = (error, input) => {
    setErrors((prevState) => ({ ...prevState, [input]: error }));
  };

  const register = () => {
    // navigation("/signup/step-four", {
    //   replace: true,
    //   state: {
    //     user: navigate.state.user,
    //     isEmployer: navigate.state.isEmployer,
    //     nickname: nickname,
    //     bio: bio,
    //   },
    // });
  };

  return (
    <div className="SignUpStepTwo">
      <div className="square-left-signup-steptwo">
        <div className="title-field-signup-steptwo">
          <div className="first-title-field-signup-steptwo">
            <div className="first-icon-signup-steptwo"></div>
            <div className="first-title-signup-steptwo">Profil Seçimi</div>
            <div className="line-signup-steptwo"></div>
          </div>
          <div className="second-title-field-signup-steptwo">
            <div className="second-icon-signup-steptwo"></div>
            <div className="second-title-signup-steptwo">Profil Oluşturma</div>
            <div className="line-signup-steptwo"></div>
          </div>
        </div>
      </div>
      <div className="square-right-signup-steptwo">
        <div className="text-field-signup-steptwo">
          <div className="text-one-signup-steptwo">
            Herkese açık profilini oluşturalım
          </div>
          <div className="text-two-signup-steptwo">
            Kullanıcı adını, resmini ve açıklama kısmını belirle.
          </div>
        </div>
        <div className="upload-photo-field-signup-steptwo">
          <img src={uploadPhotoImage} alt="upload" />
        </div>
        <div className="give-user-name-signup-steptwo">
          <InputComponent
            type="text"
            inputClassName="small-input"
            placeHolder="Kullanıcı Adın"
            id="title"
            onChange={(e) => setNickname(e.target.value)}
            error={errors.nickname}
          />
        </div>
        <div className="give-user-description-signup-steptwo">
          <textarea
            id="description"
            rows="10"
            cols="25"
            placeholder="Kendini açıkla"
            onChange={(e) => setBio(e.target.value)}
            error={errors.bio}
          />
        </div>
        <div className="buttons-field-signup-steptwo">
          <div className="turn-back-button-signup-steptwo">
            <Link to="/signup/step-one" exact>
              <button className="btn-signup-stepone">Geri</button>
            </Link>
          </div>
          <div className="forward-button-signup-steptwo" onClick={validate}>
            <button className="btn-signup-stepone">İleri</button>
          </div>
        </div>
      </div>
    </div>
  );
}
