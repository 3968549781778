import React from "react";

import "./DataSecurityComponent.scss";

export default function SettingsContactComponent() {
  return (
    <div className="ProfileInformationComponent">
      <div className="ProfileInformation">
        <h1>Veriler ve Gizlilik</h1>

        <div className="ProfileInformationLine" />
      </div>
    </div>
  );
}
