import React from 'react';

//CSS
import "./BusinessCandidates.scss";

//Images
import Candidates from "../../../assets/business/candidates/candidates.png";

export default function BusinessCandidates() {
  return (
    <div className="BusinessMain">
        <div className="BusinessTopBar">
            <h1>Adaylar</h1>
        </div>
        <div className="BusinessMainPages">
            <div className="BusinessPages">
                <div className="BusinessPanelCandidates">
                  <h3>Toplam 100 Aday Mevcut</h3>
                  <hr/>
                  <div className='CandidatesCard'>
                    <div className='CandidateImage'>
                      <img src={Candidates} alt='Candidates'/>
                    </div>
                    <div className='CandidateInfo'>
                      <h5>Erdem Alptuğ Öksüz, 24 | UI-UX Designer, CMNSoftware</h5>
                      <h6>Bilgisayar Programcılığı (MYO) | Ön Lisans - Öğrenci<br/>Medipol Üniversitesi | 2021-2023</h6>
                    </div>
                    <div className=''>
                      <h5></h5>
                    </div>
                    <hr/>
                  </div>
                  <div className='CandidatesCard'>
                    <div className='CandidateImage'>
                      <img src={Candidates} alt='Candidates'/>
                    </div>
                    <div className='CandidateInfo'>
                      <h5>Erdem Alptuğ Öksüz, 24 | UI-UX Designer, CMNSoftware</h5>
                      <h6>Bilgisayar Programcılığı (MYO) | Ön Lisans - Öğrenci<br/>Medipol Üniversitesi | 2021-2023</h6>
                    </div>
                    <hr/>
                  </div>
                  <div className='CandidatesCard'>
                    <div className='CandidateImage'>
                      <img src={Candidates} alt='Candidates'/>
                    </div>
                    <div className='CandidateInfo'>
                      <h5>Erdem Alptuğ Öksüz, 24 | UI-UX Designer, CMNSoftware</h5>
                      <h6>Bilgisayar Programcılığı (MYO) | Ön Lisans - Öğrenci<br/>Medipol Üniversitesi | 2021-2023</h6>
                    </div>
                    <hr/>
                  </div>
                </div>
            </div>
        </div>
    </div>
  )
}
