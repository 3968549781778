import React from "react";
//screens
import SignInScreen from "./screens/signInScreen/SignInScreen";
import SignUpScreen from "./screens/signUpScreen/SignUpScreen";

import HomeScreen from "./screens/homeScreen/HomeScreen";
import ProfileScreen from "./screens/profileScreen/ProfileScreen";
import SettingsScreen from "./screens/settingsScreen/SettingsScreen";
import BusinessPanelScreen from "./screens/businessPanelScreen/BusinessPanelScreen";
import SignUpStepOne from "./screens/signUpScreen/signUpStepOne/signUpStepOne";
import SignUpStepTwo from "./screens/signUpScreen/signUpStepTwo/SignUpStepTwo";
import SignUpStepTwo2 from "./screens/signUpScreen/SignUpScreenStepTwoVersion2/SignUpScreenStepTwoVersion2";
import SignUpStepFour from "./screens/signUpScreen/SingUpStepFour/SignUpStepFour";
import SignUpStepThree from "./screens/signUpScreen/SignUpStepThree/SignUpStepThree";

import WorkSearchScreen from "./screens/worksearchScreen/WorkSearchScreen";

import ControlScreen from "./screens/controlScreen/controlScreen";

import ResetPasswordScreen from "./screens/resetPassScreen/ResetPassScreen";
import NotFoundScreen from "./screens/notfoundScreen/NotFoundScreen";

import { Routes, Route } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import SignInEmployerScreen from "./screens/signInScreen/SignInEmployerScreen";

const mapStateToProps = (state) => ({
  dark: state.site.dark,
  user: state.auth.user,
});

function Router() {
  const user = useSelector((state) => state.auth.user);

  console.log(user);

  return (
    <Routes>
      <Route path="/" exact element={<HomeScreen />} />

      <Route path="/signin" element={<SignInScreen />} />
      <Route path="/signin-employer" element={<SignInEmployerScreen />} />

      {user != null ? (
        <Route path="/profile" element={<ProfileScreen />} />
      ) : (
        <Route path="/" exact element={<HomeScreen />} />
      )}

      {user != null ? (
        <Route path="/settings" element={<SettingsScreen />} />
      ) : (
        <Route path="/" exact element={<HomeScreen />} />
      )}

      <Route path="/business" element={<BusinessPanelScreen />} />

      <Route path="/control" element={<ControlScreen />} />

      <Route path="/signup" element={<SignUpScreen />} />
      <Route path="/signup/step-one" element={<SignUpStepOne />} />
      <Route path="/signup/step-two" element={<SignUpStepTwo />} />
      <Route path="/signup/step-two-v2" element={<SignUpStepTwo2 />} />
      <Route path="/signup/step-three" element={<SignUpStepThree />} />
      <Route path="/signup/step-four" element={<SignUpStepFour />} />

      <Route path="/work-search" exact element={<WorkSearchScreen />} />

      <Route path="/reset-password" exact element={<ResetPasswordScreen />} />

      <Route path="*" element={<NotFoundScreen />} />
    </Routes>
  );
}

export default connect(mapStateToProps)(Router);
