import React from "react";
import "./SignUpStepFour.scss";

//İcons
import openPeepsSittingImage from "../../../assets/signupImage/open-peeps-sitting.svg";
import { Link, useLocation } from "react-router-dom";

export default function SignUpStepFour() {
  const navigate = useLocation();
  console.log(navigate)
  return (
    <div className="SignUpStepFour">
      <div className="square-left-signup-stepfour">
        <div className="title-field-signup-stepfour">
          <div className="first-title-field-signup-stepfour">
            <div className="first-icon-signup-stepfour"></div>
            <div className="first-title-signup-stepfour">Profil Seçimi</div>
            <div className="line-signup-stepfour"></div>
          </div>

          <div className="second-title-field-signup-stepfour">
            <div className="second-icon-signup-stepfour"></div>
            <div className="second-title-signup-stepfour">Profil Oluşturma</div>
            <div className="line-signup-stepfour"></div>
          </div>
          <div className="third-title-field-signup-stepfour">
            <div className="third-icon-signup-stepfour"></div>
            <div className="third-title-signup-stepfour">Artık Hazırsın</div>
            <div className="line-signup-stepfour"></div>
          </div>
        </div>
      </div>
      <div className="square-right-signup-stepfour">
        <div className="image-signup-stepfour">
          <img src={openPeepsSittingImage}></img>
        </div>
        <div className="text-field-signup-stepfour">
          <div className="text-one-signup-stepfour">
            Artık hazırsın, hemen başlayalım!
          </div>
          <div className="text-two-signup-stepfour">
            İstersen geri dönüp seçimlerini değiştirebilirsin.
          </div>
          <div className="finishButton">
            <Link to="/" exact>
              <button className="btn-signup-stepone">Bitir</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
