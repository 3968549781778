import React from "react";
import "./HomeScreen.scss";

import { useSelector } from "react-redux";

import TopBar from "../../components/headerComponent/TopBar";
import TopBarAuth from "../../components/headerComponent/TopBarAuth";
import WorkSearch from "../../components/workSearchComponent/WorkSearch";
import SliderComponent from "../../components/sliderComponent/SliderComponent";
//import Recommendation from "../../components/recommendationComponent/Recommendation";
//import HighLights from "../../components/highlightsComponent/HighLights";
import FeaturedJobComponent from "../../components/featuredJobComponent/FeaturedJob";
import AdviceComponent from "../../components/adviceComponent/AdviceComponent";
import Footer from "../../components/footerComponent/footer";

import LogoComp from "../../assets/logo/esitcvlogo.png";

export default function HomeScreen() {
  const auth = useSelector((state) => state.auth);

  return (
    <div className="homeComponent">
      <h1 className="reklamText ">
        Çalışan mı arıyorsun?{" "}
        <a className="reklamText bold " rel="nofollow" href="/">
          Hemen ilan ver!
        </a>
      </h1>

      {auth.user != null ? <TopBarAuth /> : <TopBar />}

      <WorkSearch />

      <FeaturedJobComponent />

      <SliderComponent />

      <AdviceComponent />

      <div className="supporterArea">
        <img
          src={LogoComp}
          alt="Geleceğe Yatırım"
          className="supporterAreaImg"
        />
        <img
          src={LogoComp}
          alt="Geleceğe Yatırım"
          className="supporterAreaImg"
        />
        <img
          src={LogoComp}
          alt="Geleceğe Yatırım"
          className="supporterAreaImg"
        />
        <img
          src={LogoComp}
          alt="Geleceğe Yatırım"
          className="supporterAreaImg"
        />
        <img
          src={LogoComp}
          alt="Geleceğe Yatırım"
          className="supporterAreaImg"
        />
      </div>

      <Footer />
    </div>
  );
}
