import { configureStore } from '@reduxjs/toolkit'

import siteReducer from "./site"
import authReducer from "./Auth/reducer"

export default configureStore({
    reducer: {
        site: siteReducer,
        auth: authReducer
    },
})