import React from "react";

import "./ProfileInformationComponent.scss";

export default function ProfileInformationComponent() {
  return (
    <div className="ProfileInformationComponent">
      <div className="ProfileInformation">
        <h1>Profil Bilgileri</h1>

        <div className="ProfileInformationLine" />
        <div className="ProfileInformationTitle">
          <h3>İsim, Konum, Sektör</h3>
          <div className="ProfileInformationDescription">
            <h5>İsminizi ve profil bilgilerinizi düzenleyin</h5>
          </div>
          <div className="ProfileInformationButton">
            <a href="">Değiştir</a>
          </div>
        </div>
      </div>

      <div className="ProfileInformationLine" />
      <div className="ProfileInformation">
        <h1>Site Tercihleri</h1>
        <div className="ProfileInformationLine" />
        <div className="ProfileInformationTitle">
          <h3>Dil</h3>
          <div className="ProfileInformationDescription">
            <h5>EşitCV'yi hangi dilde kullanmak istediğinizi seçin</h5>
          </div>
        </div>
      </div>

      <div className="ProfileInformationLine" />
      <div className="ProfileInformation">
        <h1>Hesap Yönetimi</h1>
        <div className="ProfileInformationLine" />
        <div className="ProfileInformationTitle">
          <h3>Hesabını Dondur</h3>
          <div className="ProfileInformationDescription">
            <h5>Hesabınızı geçici orak devre dışı bırakın</h5>
          </div>
        </div>

        <div className="ProfileInformationLine" />
        <div className="ProfileInformationTitle">
          <h3 className="ProfileInformationDeleteAccount"><a href='#'>Hesabını Kapat</a></h3>
          <div className="ProfileInformationDescription">
            <h5>
              Bu kalıcı olarak gerçekleşecek ve geri dönüşü olmayan bir
              işlemdir.
              <br />
              Daha fazlası için bilgi alın
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
}
