import React, { useState, useEffect } from "react";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";

import "./FeaturedJob.scss";

import CMNLogo from "../../assets/logo/cmnlogopurple.png";
import PlatinLogo from "../../assets/logo/platinlogo.png";
import PlatinLogo2 from "../../assets/logo/platinsiyah.jpg";
import YGLogo from "../../assets/logo/YGLogo.png";
import axios from "axios";

export default function FeaturedJob() {
  const [workData, setWorkData] = useState([]);

  useEffect(() => {
    axios
      .get("https://api.esitcv.com/api/GetAdvertsWithoutAuthorize/getAll", {
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          isActive: true,
          isDeleted: false,
          isAscending: false,
          currentPage: 1,
          pageSize: 20,
        },
      })
      .then((res) => {
        console.log(res.data.data);
        setWorkData(res.data.data.advertGetDtos);
      });
  }, []);

  const data = [
    {
      id: 1,
      logo: CMNLogo,
      companyName: "CMNSoftware",
      position: "Front End Developer",
    },
  ];

  return (
    <div className="FeaturedJob">
      <div className="FeaturedJobTitles">
        <h className="FeaturedJobTitle">
          Öne çıkan <h className="FeaturedJobTitleInside">iş ilanları</h>
        </h>
      </div>
      <ScrollingCarousel
        slide={2}
        swipping={true}
        leftIcon={false}
        rightIcon={false}
      >
        {workData.map((item) => {
          return (
            <Item
              id={item.id}
              logo={item.logo}
              companyName={item.companyName}
              position={
                item.advert_Information != null
                  ? item.advert_Information.position
                  : "Belirtilmedi"
              }
            />
          );
        })}
      </ScrollingCarousel>
    </div>
  );
}

function Item(props) {
  return (
    <div className="FeaturedJobCard" key={props.id}>
      <img src={props.logo} alt="CMNLogo" />
      <h3 className="FeaturedJobCompanyName">{props.companyName}</h3>
      <h5 className="FeaturedJobPosition">{props.position}</h5>
    </div>
  );
}
