import React, { useState, useEffect } from "react";
import "./SignUpScreen.scss";

//Components
import InputComponent from "../../components/inputComponent/input";
//import InputButtonComponent from "../../components/inputButtonComponent/inputButton";
import InputRadioButton from "../../components/InputRadioButtonComponent/InputRadioButton";

import { useNavigate } from "react-router-dom";
//Icons
//import MailIcon from "../../assets/icons/mail-outline.svg";
//import PasswordIcon from "../../assets/icons/lock-closed-outline.svg";
//import UserIcon from "../../assets/icons/person-outline.svg";
//import { Checkbox } from "@mui/material";*/
import EsitCvLogo from "../../assets/logo/esitcvlogo.png";
import LoadingComponent from "../../components/loadingComponent/loadingComponent";
import { Button, IconButton, Snackbar } from "@mui/material";

export default function SignUpScreen() {
  const navigation = useNavigate();

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVerify, setPasswordVerify] = useState("");

  const [errors, setErrors] = useState([]);

  const [check, setCheck] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const validate = () => {
    let isValid = true;
    if (name.length < 3) {
      handleError("Adınız minimum 3 karakter olmalı", "name");
      isValid = false;
    }
    if (surname.length < 2) {
      handleError("Soyadınız minimum 2 karakter olmalı", "surname");
      isValid = false;
    }
    if (email.length < 4) {
      handleError("Email adresiniz 3 karakterden az olamaz", "email");
      isValid = false;
    }
    if (password.length < 8) {
      handleError("Şifreniz en az 8 karakterden az olamaz", "password");
      isValid = false;
    }
    if (passwordVerify.length < 8) {
      handleError("Şifreniz en az 8 karakterden az olamaz", "passwordVerify");
      isValid = false;
    }
    if (
      password.length < 8 &&
      passwordVerify.length < 8 &&
      password !== passwordVerify
    ) {
      handleError("Şifreniz eşleşmiyor", "password");
      isValid = false;
    }

    if (!check) {
      handleError(
        "Lütfen kullanıcı sözleşmesini okuduktan sonra işaretleyiniz",
        "check"
      );
      isValid = false;
    }

    if (isValid) {
      register();
      console.log("basarılı");
    } else {
      handleClick();
    }
  };

  const handleError = (error, input) => {
    setErrors((prevState) => ({ ...prevState, [input]: error }));
  };

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const register = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      navigation("/signup/step-one", {
        replace: true,
        state: {
          name,
          surname,
          email,
          password,
        },
      });
    }, 1000);
  };

  return (
    <div className="signup">
      <div className="signup-square-left">
        <div className="signup-logo">
          <img src={EsitCvLogo} alt="esitcv" className="esitcvlogo-signup" />
        </div>
        <div className="signup-texts">
          <h2 className="signup-texts-h2">Merhaba, hoş geldin!</h2>
          <span className="signup-texts-span">
            Hemen eşitCV’nin ayrıcalıklarından yararlan.
          </span>
        </div>
        <form className="signup-form">
          <div className="signup-name-surname-input">
            <InputComponent
              type="text"
              inputClassName="small-input"
              id="name"
              placeholder=" "
              label="Ad"
              LabelClassName="label"
              onChange={(e) => setName(e.target.value)}
              error={errors.name}
            />

            <InputComponent
              type="text"
              inputClassName="small-input"
              id="surname"
              placeholder=" "
              label="Soyad"
              LabelClassName="label"
              onChange={(e) => setSurname(e.target.value)}
              error={errors.surname}
            />
          </div>
          <div className="signup-email-input">
            <InputComponent
              type="email"
              inputClassName="input"
              id="email"
              placeholder=" "
              label="E-Posta"
              LabelClassName="inputLabel"
              onChange={(e) => setEmail(e.target.value)}
              error={errors.email}
            />
          </div>
          <div className="signup-pasword-repeat-password">
            <InputComponent
              type="password"
              inputClassName="small-input"
              id="password"
              placeholder=" "
              label="Şifre"
              LabelClassName="label"
              onChange={(e) => setPassword(e.target.value)}
              error={errors.password}
            />
            <InputComponent
              type="password"
              inputClassName="small-input"
              id="password"
              placeholder=" "
              label="Şifre Tekrar"
              LabelClassName="label"
              onChange={(e) => setPasswordVerify(e.target.value)}
              error={errors.passwordVerify}
            />
          </div>
          <div className="signup-checkboxs">
            <div className="signup-first-checkbox">
              <InputRadioButton
                text="Kullanıcı sözleşmesini okudum, kabul ediyorum."
                check={check}
                setCheck={setCheck}
                radioStyle={"radioStyle"}
              />
            </div>
          </div>
          <div className="signup-buttons">
            <div className="signup-btn" onClick={validate}>
              Hesap Oluştur
            </div>

            {/* <div className="signup-border-field">
              <div className="signup-border"></div>
              <span className="signup-border-span">Ya da</span>
            </div>
            <GoogleButton
              iconName={GoogleIcon}
              buttonTitle="Google hesabın ile kayıt ol"
            /> */}
          </div>
        </form>

        <a className="signup-link-question" href="/signin">
          Daha önceden hesabın var mı? Giriş yap
        </a>
      </div>

      <div className="signup-square-right">
        <div className="signup-text-field">
          <div className="signup-text-up">
            <span className="signup-text-up-span">eşitCV</span>
          </div>
          <div className="signup-text-down">
            <span className="signup-text-down-span">Eşit Bir Gelecek</span>
          </div>
        </div>
      </div>

      {isLoading ? <LoadingComponent /> : null}
      {console.log(errors)}

      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message={errors.name}
      />
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message={errors.surname}
      />
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message={errors.check}
      />
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message={errors.email}
      />
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message={errors.password}
      />
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message={errors.passwordVerify}
      />
    </div>
  );
}
