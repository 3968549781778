import React, { useEffect } from "react";
import "./SignUpScreenStepTwoVersion2.scss";

//Components
import InputRadioButton from "../../../components/InputRadioButtonComponent/InputRadioButton";
import InputComponent from "../../../components/inputComponent/input";

//İcons
import EsitCvLogo from "../../../assets/icons/esitcv.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { registerEmployer } from "../../../redux/Auth/actions";

export default function SignUpScreenStepTwoVersion2() {
  let navigation = useLocation();
  let navigate = useNavigate();

  const dispatch = useDispatch();

  const [user, setUser] = React.useState([]);

  const handleClick = () => {
    console.log("clicked");
    dispatch(registerEmployer(user, navigate));
  };

  useEffect(() => {
    setUser({ ...user, ...navigation.state.user });
  }, []);

  return (
    <div className="SignUpScreenStepTwoVersion2">
      <div className="square-left-signup-steptwo-version2">
        <div className="logo-signup-steptwo-version2">
          <img src={EsitCvLogo} alt="logo" />
        </div>
        <div className="texts-signup-steptwo-version2">
          <h2 className="texts-h2-signup-steptwo-version2">
            Merhaba, hoş geldin!
          </h2>
          <span className="texts-span-signup-steptwo-version2">
            Hemen eşitCV’nin ayrıcalıklarından yararlan.
          </span>
        </div>
        <form className="form-signup-steptwo-version2">
          <div className="company-name-input-signup-steptwo-version2">
            <InputComponent
              type="text"
              inputClassName="input"
              id="company-name"
              label="Şirket"
              LabelClassName="label"
              onChange={(e) =>
                setUser({ ...user, companyName: e.target.value })
              }
            />
          </div>
          <div className="province-and-district-input-signup-steptwo-version2">
            <InputComponent
              type="text"
              inputClassName="small-input"
              id="province"
              label="İl"
              LabelClassName="label"
              onChange={(e) => setUser({ ...user, province: e.target.value })}
            />

            <InputComponent
              type="text"
              inputClassName="small-input"
              id="İlçe"
              label="İlçe"
              LabelClassName="label"
              onChange={(e) => setUser({ ...user, district: e.target.value })}
            />
          </div>
          <div className="tax-administration-province-and-district-signup-steptwo-version2">
            <InputComponent
              type="text"
              inputClassName="small-input"
              id="Vergi D. İl"
              label="VergiD.İl"
              LabelClassName="label"
              onChange={(e) =>
                setUser({ ...user, taxAdministrationProvince: e.target.value })
              }
            />

            <InputComponent
              type="text"
              inputClassName="small-input"
              id="Vergi D. İlçe"
              label="VergiD.İlçe"
              LabelClassName="label"
              onChange={(e) =>
                setUser({ ...user, taxAdministrationDistrict: e.target.value })
              }
            />
          </div>
          <div className="tax-number-signup-steptwo-version2">
            <InputComponent
              type="text"
              inputClassName="input"
              id="Vergi No"
              label="VergiNo"
              LabelClassName="label"
              onChange={(e) => setUser({ ...user, taxNumber: e.target.value })}
              maxLength="10"
            />
          </div>
          <div className="check-field-signup-steptwo-version2">
            <InputRadioButton text="Kullanıcı sözleşmesini okudum, kabul ediyorum." />
          </div>
          <div className="button-field-signup-steptwo-version2">
            <div onClick={handleClick} className="btn-signup-steptwo-version2">
              Kayıt ol
            </div>
          </div>
        </form>
      </div>
      <div className="square-right-signup-steptwo-version2">
        <div className="text-field-signup-steptwo-version2">
          <div className="text-up-signup-steptwo-version2">
            <span className="text-up-span-signup-steptwo-version2">eşitCV</span>
          </div>
          <div className="text-down-signup-steptwo-version2">
            <span className="text-down-span-signup-steptwo-version2">
              Eşit Bir Gelecek
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
