export const LOGIN_START = "@LOGIN_START";
export const LOGIN_SUCCESS = "@LOGIN_SUCCESS";
export const LOGIN_FAILED = "@LOGIN_FAILED";

export const REGISTER_START = "@REGISTER_START";
export const REGISTER_SUCCESS = "@REGISTER_SUCCESS";
export const REGISTER_FAILED = "@REGISTER_FAILED";

export const VERIFICATION_CODE_MATCH_START = "@VERIFICATION_CODE_MATCH_START";
export const VERIFICATION_CODE_MATCH_SUCCESS =
  "@VERIFICATION_CODE_MATCH_SUCCESS";
export const VERIFICATION_CODE_MATCH_FAIL = "@VERIFICATION_CODE_MATCH_FAIL";

export const VERIFICATION_CODE_MAIL_MATCH_START =
  "@VERIFICATION_CODE_MAIL_MATCH_START";
export const VERIFICATION_CODE_MAIL_MATCH_SUCCESS =
  "@VERIFICATION_CODE_MAIL_MATCH_SUCCESS";
export const VERIFICATION_CODE_MAIL_MATCH_FAIL =
  "@VERIFICATION_CODE_MAIL_MATCH_FAIL";

export const VERIFICATION_CODE_PHONE_MATCH_START =
  "@VERIFICATION_CODE_PHONE_MATCH_START";
export const VERIFICATION_CODE_PHONE_MATCH_SUCCESS =
  "@VERIFICATION_CODE_MAIL_PHONE_SUCCESS";
export const VERIFICATION_CODE_PHONE_MATCH_FAIL =
  "@VERIFICATION_CODE_MAIL_PHONE_FAIL";

export const FORGOT_PASSWORD_MAIL = "@FORGOT_PASSWORD_MAIL";
export const FORGOT_PASSWORD_PHONE = "@FORGOT_PASSWORD_PHONE";

export const SEND_CODE_FOR_MAIL = "@SEND_CODE_FOR_MAIL";
export const SEND_CODE_FOR_PHONE = "@SEND_CODE_FOR_PHONE";

export const LOG_OUT = "@LOG_OUT";

export const CHANGE_PASSWORD_START = "@CHANGE_PASSWORD_START";
export const CHANGE_PASSWORD_SUCCESS = "@CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILED = "@CHANGE_PASSWORD_FAILED";