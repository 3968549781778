import React from 'react'
import "./WorkApplicationComponent.scss";

import ornek from "../../../assets/ornekFoto/2.jpg"
import WorkApplicationCard from './WorkApplicatonCard/WorkApplicationCard';

export default function WorkApplicationComponent() {

    const data = [
        {
            id: 1,
            sirketAdi: "CMNSoftware",
            pozisyonAdi: "Front-End Developer",
            konum: "İstanbul (Avrupa)",
            calismaTuru: "Tam Zamanlı",
            durum: true
        },
        {
            id: 2,
            sirketAdi: "CMNSoftware",
            pozisyonAdi: "Back-End Developer",
            konum: "İstanbul (Avrupa)",
            calismaTuru: "Yarı Zamanlı",
            durum: false
        },
        {
            id: 3,
            sirketAdi: "Platin Oto",
            pozisyonAdi: "Muhasebeci",
            konum: "İstanbul (Anadolu)",
            calismaTuru: "Tam Zamanlı",
            durum: true
        }
    ]


    return (
        <div className='WorkApplicationComponent' >

            <div className='WorkApplication' >
                <h className='ProfileTitle' >Başvurular</h>
                <h className='ProfileTitleDesc' >Başvuru yaptığın iş ilanları burada yer almaktadır.</h>
                <div className='PersonelInformationAltCizgi AltCizgiUzun' />

                {data.map((item, index) => {
                    return (
                        <WorkApplicationCard
                            key={index}
                            BusinessLogo={ornek}
                            sirketAdi={item.sirketAdi}
                            pozisyonAdi={item.pozisyonAdi}
                            konum={item.konum}
                            calismaTuru={item.calismaTuru}
                            durum={item.durum}
                        />
                    )
                })}
            </div>

        </div>
    )
}
