import React from 'react'
import "./controlScreen.scss";

import UserIcon from "../../assets/icons/User.svg";
import FileIcon from "../../assets/icons/File.svg";
import BookMarkIcon from "../../assets/icons/Bookmark.svg";
import SettingsIcon from "../../assets/icons/Settings.svg";

import TopBar from "../../components/headerComponent/TopBar";
import TopBarAuth from "../../components/headerComponent/TopBarAuth";

export default function controlScreen() {
    const name = localStorage.getItem("name");
    const surname = localStorage.getItem("surname");
    const email = localStorage.getItem("email");
    const password = localStorage.getItem("password");
    const nickname = localStorage.getItem("nickname");
    const bio = localStorage.getItem("bio");
  return (
    <div className='ControlScreen'>
    {name != null && surname != null ? <TopBarAuth /> : <TopBar />}
        <div className='ControlScreenUser'>
            <h1>Merhaba Erdem Alptuğ!</h1>
            <h3>İhtiyacın Olan Her Şey Burada</h3>
        </div>
        <div className='ControlScreenMainComponent'>
            <div className='ControlScreenComponent'>
                <div className='Col-3'>
                    <a href='#'>
                        <img src={UserIcon} alt='Profil' />
                        <h5>Profil</h5>
                    </a>
                </div>
                <div className='Col-3'>
                    <a href='#'>
                        <img src={FileIcon} alt='Başvurular' />
                        <h5>Başvurular</h5>
                    </a>
                </div>
                <div className='Col-3'>
                    <a href='#'>
                        <img src={BookMarkIcon} alt='Kaydedilenler' />
                        <h5>Kaydedilenler</h5>
                    </a>
                </div>
                <div className='Col-3'>
                    <a href='#'>
                        <img src={SettingsIcon} alt='Ayarlar' />
                        <h5>Ayarlar</h5>
                    </a>
                </div>
            </div>
        </div>
    </div>
  )
}
