import React from "react";

import "./AccountAccessComponent.scss";

export default function AccountAccessComponent() {
  return (
    <div className="ProfileInformationComponent">
      <div className="ProfileInformation">
        <h1>Hesap Erişimi</h1>

        <div className="ProfileInformationLine" />
        <div className="ProfileInformationTitle">
          <h3>Kayıtlı E-Posta Adresi</h3>
          <div className="ProfileInformationDescription">
            <h5>Hesabınıza kayıtlı e-posta adresini düzenleyin</h5>
          </div>
          <div className="ProfileInformationButton">
            <a href="">Düzenle</a>
          </div>
        </div>

        <div className="ProfileInformationLine" />
        <div className="ProfileInformationTitle">
          <h3>Kayıtlı Telefon Numarası</h3>
          <div className="ProfileInformationDescription">
            <h5>Hesabınıza kayıtlı telefon numaranızı düzenleyin</h5>
          </div>
          <div className="ProfileInformationButton">
            <a href="">Düzenle</a>
          </div>
        </div>

        <div className="ProfileInformationLine" />
        <div className="ProfileInformationTitle">
          <h3>Şifre Değiştir</h3>
          <div className="ProfileInformationDescription">
            <h5>Şifrenizi daha güçlü bir kombinasyonla değiştirin</h5>
          </div>
          <div className="ProfileInformationButton">
            <a href="">Değiştir</a>
          </div>
        </div>

        <div className="ProfileInformationLine" />
        <div className="ProfileInformationTitle">
          <h3>İki Aşamalı Doğrulama</h3>
          <div className="ProfileInformationDescription">
            <h5>
              Hesabınızın güvenliğini arttırmak için bu özelliği etkinleştirin
            </h5>
          </div>
          <div className="ProfileInformationButton">
            <a href="">Değiştir</a>
          </div>
        </div>

        <div className="ProfileInformationLine" />
        <div className="ProfileInformationTitle">
          <h3 className="ProfileInformationDeleteAccount">Şifremi Unuttum</h3>
          <div className="ProfileInformationDescription">
            <h5>
              Kayıtlı e-posta adresinize bir şifre değiştirme bağlantısı gönderin
            </h5>
          </div>
          <div className="ProfileInformationButton">
            <a href="">Gönder</a>
          </div>
        </div>
      </div>
    </div>
  );
}
