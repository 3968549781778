import React from 'react'
import ornek from "../../../assets/ornekFoto/2.jpg"

import SavedWorksCard from "./SavedWorksCard/SavedWorkCard";

export default function SavedWorksComponent() {

    const data = [
        {
            id: 1,
            sirketAdi: "CMNSoftware",
            pozisyonAdi: "Front-End Developer",
            konum: "İstanbul (Avrupa)",
            calismaTuru: "Tam Zamanlı",
            durum: true
        },
        {
            id: 2,
            sirketAdi: "CMNSoftware",
            pozisyonAdi: "Back-End Developer",
            konum: "İstanbul (Avrupa)",
            calismaTuru: "Yarı Zamanlı",
            durum: false
        },
        {
            id: 3,
            sirketAdi: "Platin Oto",
            pozisyonAdi: "Muhasebeci",
            konum: "İstanbul (Anadolu)",
            calismaTuru: "Tam Zamanlı",
            durum: true
        }
    ]

    return (
        <div className='WorkApplicationComponent' >
            <div className='WorkApplication' >
                <h className='ProfileTitle' >Kaydedilen İlanlar</h>
                <h className='ProfileTitleDesc' >Beğenip, kaydettiğin veya daha sonra bakarım dediğin ilanları senin için listeledik.</h>
                <div className='PersonelInformationAltCizgi AltCizgiUzun' />

                {
                    data.map((item, index) => {
                        return (
                            <SavedWorksCard
                                key={index}
                                BusinessLogo={ornek}
                                sirketAdi={item.sirketAdi}
                                pozisyonAdi={item.pozisyonAdi}
                                konum={item.konum}
                                calismaTuru={item.calismaTuru}
                                durum={item.durum}
                            />
                        )
                    })
                }


            </div>
        </div>
    )
}
