import React from "react";
import "./PersonalInformationComponent.scss";

import OrnekPP from "../../../assets/ornekFoto/2.jpg";

import Eye from "../../../assets/icons/eye-outline.svg";
import EyeOff from "../../../assets/icons/eye-off-outline.svg";
import { useDispatch, useSelector } from "react-redux";
import { changePassword } from "../../../redux/Auth/actions";

export default function PersonalInformationComponent() {
  const [isOff, setIsOff] = React.useState(false);
  const [isOff2, setIsOff2] = React.useState(false);
  const [isOff3, setIsOff3] = React.useState(false);

  const [oldPassword, setOldPasword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [renewPassword, setReNewPassword] = React.useState("");

  const auth = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  console.log(auth.user[0].username);
  return (
    <div className="PersonalInformationComponent">
      <div className="PersonelInformation">
        <h className="ProfileTitle">Kişisel Bilgiler</h>
        <h className="ProfileTitleDesc">
          Ad, soyad, profil fotoğrafı, e-posta ve telefon bilgilerini
          düzenleyebilirsin.
        </h>
        <div className="PersonelInformationAltCizgi AltCizgiUzun" />
        <div className="Personel">
          <div className="PersonelInfo">
            <h className="ProfileInputTitle">Adınız ve Soyadınız</h>
            <input
              className="ProfileInput"
              type="text"
              name="NameAndSurname"
              value={auth.user[0].name + " " + auth.user[0].surname}
              placeholder="Adınız ve Soyadınız"
            />
            <div className="PersonelInformationAltCizgi" />
            <h className="ProfileInputTitle">E-posta Adresiniz</h>
            <input
              className="ProfileInput"
              type="email"
              name="email"
              value={auth.user[0].email}
              placeholder="E-Mail Adresiniz"
            />
            <div className="PersonelInformationAltCizgi" />
            <h className="ProfileInputTitle">Telefon Numaranız</h>
            <div className="ButtonComponent">
              <input
                className="ProfileInput"
                type="tel"
                name="phonenumber"
                value={auth.user[0].phoneNumber}
                placeholder="Telefon Numaranız"
              />
              {/* <button className="ProfileButton" style={{backgroundColor:      auth.user[0].isPhoneNumberConfirmed ? "#53FF19" : "#FF0000"}} >{
                auth.user[0].isPhoneNumberConfirmed ? "Onaylı" : "Onaylanmadı"
              }</button> */}
            </div>
            {/* <div className="PersonelInformationAltCizgi" />
            <h className="ProfileInputTitle">Profil Fotoğrafınız</h>
            <div className="ButtonComponent">
              <h className="ProfileInput">vesikalik.jpg</h>
              <button className="ProfileButton ProfilePhotoButton">
                Dosya Seç
              </button>
            </div> */}
            <div className="PersonelInformationAltCizgi" />
            <button className="ProfileSaveButton">Kaydet</button>
          </div>

          {/* <div className="ProfilePhotoArea">
            <img
              className={`ProfilePhotoSrc ${isOpen}`}
              src={OrnekPP}
              alt="ProfilePhoto"
              onMouseEnter={() => {
                isOpen === ""
                  ? setIsOpen("OnMouseEnterChangeProfilePhotoText")
                  : setIsOpen("");
              }}
              onMouseDown={() => {
                isOpen === ""
                  ? setIsOpen("OnMouseEnterChangeProfilePhotoText")
                  : setIsOpen("");
              }}
            />
            {isOpen ? <h className="ChangeProfilePhotoText">Değiştir</h> : null}
          </div> */}
        </div>
      </div>

      <div className="ChangePassword">
        <h className="ProfileTitle">Şifreni Değiştir</h>
        <h className="ProfileTitleDesc">
          Şifreni değiştirebilir veya sıfırlayabilirsin.
        </h>
        <div className="PersonelInformationAltCizgi AltCizgiUzun" />
        <div className="Personel">
          <div className="PersonelInfo">
            <h className="ProfileInputTitle">Eski Şifreniz</h>
            <div className="ButtonComponent">
              <input
                className="ProfileInput"
                type={isOff ? "text" : "password"}
                name="phonenumber"
                value={oldPassword}
                onChange={(e) => {
                  setOldPasword(e.target.value);
                }}
                placeholder="Eski şifreniz"
              />
              <img
                className="ShowPasswordIcon"
                alt="ShowPassword"
                src={isOff ? Eye : EyeOff}
                onClick={() => {
                  isOff ? setIsOff(false) : setIsOff(true);
                }}
              />
            </div>
            <div className="PersonelInformationAltCizgi" />
            <h className="ProfileInputTitle">Yeni Şifreniz</h>
            <div className="ButtonComponent">
              <input
                className="ProfileInput"
                type={isOff2 ? "text" : "password"}
                name="phonenumber"
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
                placeholder="Yeni şifreniz"
              />
              <img
                className="ShowPasswordIcon"
                alt="ShowPassword"
                src={isOff2 ? Eye : EyeOff}
                onClick={() => {
                  isOff2 ? setIsOff2(false) : setIsOff2(true);
                }}
              />
            </div>
            <div className="PersonelInformationAltCizgi" />
            <h className="ProfileInputTitle">Tekrar Yeni Şifreniz</h>
            <div className="ButtonComponent">
              <input
                className="ProfileInput"
                type={isOff3 ? "text" : "password"}
                name="phonenumber"
                onChange={(e) => {
                  setReNewPassword(e.target.value);
                }}
                value={renewPassword}
                placeholder="Tekrar yeni şifreniz"
              />
              <img
                className="ShowPasswordIcon"
                alt="ShowPassword"
                src={isOff3 ? Eye : EyeOff}
                onClick={() => {
                  isOff3 ? setIsOff3(false) : setIsOff3(true);
                }}
              />
            </div>
            <div className="PersonelInformationAltCizgi" />
            <button
              onClick={() => {
                dispatch(changePassword(auth.user[0].email, oldPassword, newPassword)).then
                ((res) => {
                  console.log(res)
                  console.log("şifre değiştirildi");
                }
                );
              }}
              className="ProfileSaveButton"
            >
              Kaydet
            </button>
          </div>
        </div>
      </div>

      <div className="CV">
        <h className="ProfileTitle">Özgeçmişiniz</h>
        <h className="ProfileTitleDesc">
          Özgeçmiş oluşturabilir ve özgeçmişinizi düzenleyebilirsin.
        </h>
        <div className="PersonelInformationAltCizgi AltCizgiUzun" />
        <div className="Personel">
          <div className="PersonelInfo">
            <h className="ProfileInputTitle">Özgeçmişiniz</h>
            <div className="ButtonComponent">
              <h className="ProfileInput">B2110.032100_Eren_OZ.pdf</h>
              <button className="ProfileButton ProfilePhotoButton">
                Önizleme
              </button>
            </div>
            <div className="PersonelInformationAltCizgi" />
            <div className="CVButtons">
              <button className="ProfileSaveButton">Kaydet</button>
              <button className="ProfileSaveButton">Düzenle</button>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="NotificationSettings">
        <h className="ProfileTitle">Bildirim Ayarları</h>
        <h className="ProfileTitleDesc">
          EşitCV’den iş fırsatları ve bilgilendirme e-postaları alma konusunda
          tercihini belirleyebilirsin.
        </h>
        <div className="PersonelInformationAltCizgi AltCizgiUzun" />
        <div className="Notification">
          <div className="PersonelInfo">
            <div className="ButtonComponent">
              <h className="ProfileInput">
                E-Posta ile bildirim almak istiyorum.
              </h>
            </div>
            <div className="PersonelInformationAltCizgi NotificationAltCizgi " />
            <div className="ButtonComponent">
              <h className="ProfileInput">SMS ile bilgi almak istiyorum.</h>
            </div>
            <div className="PersonelInformationAltCizgi NotificationAltCizgi " />
            <button className="ProfileSaveButton">Kaydet</button>
          </div>
        </div>
      </div> */}
    </div>
  );
}
