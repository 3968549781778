/* eslint-disable import/no-anonymous-default-export */
import {
  REGISTER_START,
  REGISTER_SUCCESS,
  REGISTER_FAILED,
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  CHANGE_PASSWORD_START,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILED,
} from "./types";

const INITIAL_STATE = {
  isAuth: false,
  isLoading: false,
  user: null,
  error: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REGISTER_START:
      return {
        ...state,
        isLoading: true,
        user: null,
        error: null,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.payload,
        error: null,
      };
    case REGISTER_FAILED:
      return {
        ...state,
        isLoading: false,
        user: null,
        error: action.payload
      };

    case LOGIN_START:
      return {
        ...state,
        isLoading: true,
        user: null,
        error: null,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.payload,
        error: null,
      };
      case LOGIN_FAILED:
      return {  
        ...state,
        isLoading: false,
        user: null,
        error: action.payload
      };

      case CHANGE_PASSWORD_START:
      return {
        ...state,
        isLoading: true,
      }
      case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
      case CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        isLoading: false,
      }

    default:
      return state;
  }
};
