import React from "react";
import "./ResetPassScreen.scss";

//Components
import InputComponent from "../../components/inputComponent/input";

//Icons
import PasswordIcon from "../../assets/icons/lock-closed-outline.svg";

export default function ResetPassScreen() {
  return (
    <div className="background">
      <div className="square square1" />
      <div className="square square2" />

      <div className="mainSquare">
        <h className="title">Şifremi Unuttum</h>
        <div className="Login">
          <form>
            <div className="cizgi" />
            <div className="Input">
              <h3 className="ResetPasswordText">Yeni Şifre</h3>
              <InputComponent
                iconName={PasswordIcon}
                alt="New Password"
                type="password"
                name="NewPassword"
                placeHolder="Yeni Şifrenizi Giriniz"
              />

              <h3 className="ResetPasswordText">Yeni Şifre Tekrar</h3>
              <InputComponent
                iconName={PasswordIcon}
                alt="New Password Again"
                type="password"
                name="NewPasswordAgain"
                placeHolder="Yeni Şifrenizi Tekrar Giriniz"
              />
            </div>
            <button className="ResetButton">Şifremi Yenile</button>
          </form>
        </div>
      </div>
    </div>
  );
}
