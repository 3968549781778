import React from "react";
import "./TopBar.scss";

import LogoComp from "../../assets/logo/esitcvlogo.png";

import { Link } from "react-router-dom";
import { AppBar, Toolbar, Typography } from "@mui/material";
import { Grid } from "semantic-ui-react";

export default function TopBar() {
  return (
    <AppBar
      position="relative"
      className="appBarComponent"
      style={{
        backgroundColor: "#f2f2f2",
      }}
    >
      <Toolbar className="appBarComponent">
        <Grid className="topBarLeft">
          <Typography variant="h6" noWrap component="a" href="/">
            <img src={LogoComp} alt="hamburger" className="esitcvlogo" />
          </Typography>

          <Typography variant="h6" component="div">
            <Link to="/work-search" exact>
              <h1 className="TitleHeader mainTitle">İş Bul</h1>
            </Link>
          </Typography>
          <Typography variant="h6" component="div">
            <Link to="/" exact>
              <h1 className="TitleHeader mainTitle">Kariyer Tavsiyesi</h1>
            </Link>
          </Typography>
        </Grid>

        <Grid className="topBarRight">
          <Typography variant="h6" component="div">
            <Link to="/signup" exact>
              <button className="HeaderButtonUser">
                <h1 className="TitleHeader authTitle HeaderButton">Üye Ol</h1>
              </button>
            </Link>
          </Typography>
          <Typography variant="h6" component="div">
            <Link to="/signin" exacth>
              <button className="HeaderButtonUser">
                <h1 className="TitleHeader authTitle HeaderButton">
                  Üye Girişi
                </h1>
              </button>
            </Link>
          </Typography>

          <Typography variant="h6" component="div">
            <Link to="/signin-employer" exacth>
              <button className="HeaderButtonEmployer">
                <h1 className="TitleHeader authTitle HeaderButton">İşveren</h1>
              </button>
            </Link>
          </Typography>
        </Grid>
      </Toolbar>
    </AppBar>

    // <AppBar position="static">
    //   <Container maxWidth="xl">
    //     <Toolbar disableGutters>
    //       <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
    //       <Typography
    //         variant="h6"
    //         noWrap
    //         component="a"
    //         href="/"
    //         sx={{
    //           mr: 2,
    //           display: { xs: 'none', md: 'flex' },
    //           fontFamily: 'monospace',
    //           fontWeight: 700,
    //           letterSpacing: '.3rem',
    //           color: 'inherit',
    //           textDecoration: 'none',
    //         }}
    //       >
    //         LOGO
    //       </Typography>

    //       <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
    //         <IconButton
    //           size="large"
    //           aria-label="account of current user"
    //           aria-controls="menu-appbar"
    //           aria-haspopup="true"
    //           color="inherit"
    //         >
    //         </IconButton>
    //         <Menu
    //           id="menu-appbar"
    //           anchorOrigin={{
    //             vertical: 'bottom',
    //             horizontal: 'left',
    //           }}
    //           keepMounted
    //           transformOrigin={{
    //             vertical: 'top',
    //             horizontal: 'left',
    //           }}
    //           sx={{
    //             display: { xs: 'block', md: 'none' },
    //           }}
    //         >

    //         </Menu>
    //       </Box>
    //       <Typography
    //         variant="h5"
    //         noWrap
    //         component="a"
    //         href=""
    //         sx={{
    //           mr: 2,
    //           display: { xs: 'flex', md: 'none' },
    //           flexGrow: 1,
    //           fontFamily: 'monospace',
    //           fontWeight: 700,
    //           letterSpacing: '.3rem',
    //           color: 'inherit',
    //           textDecoration: 'none',
    //         }}
    //       >
    //         <img src={LogoComp} alt="hamburger" className="esitcvlogo" />
    //       </Typography>

    //       <Box sx={{ flexGrow: 0 }}>
    //         <Tooltip title="Open settings">
    //           <IconButton sx={{ p: 0 }}>
    //             <Avatar alt="Remy Sharp" src="/static/images/avatar/31.jpg" />
    //           </IconButton>
    //         </Tooltip>
    //         <Menu
    //           sx={{ mt: '45px' }}
    //           id="menu-appbar"
    //           anchorOrigin={{
    //             vertical: 'top',
    //             horizontal: 'right',
    //           }}
    //           keepMounted
    //           transformOrigin={{
    //             vertical: 'top',
    //             horizontal: 'right',
    //           }}
    //         >
    //         </Menu>
    //       </Box>
    //     </Toolbar>
    //   </Container>
    // </AppBar>
  );
}
