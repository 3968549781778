import React, { useState } from 'react'
import "./WorkSearch.scss"

import SearchIcon from "../../assets/icons/search-outline.svg"

export default function WorkSearch() {

    const [searchPosition, setSearchPosition] = useState("")

    const data = [
        {
            title: "Front-End Developer",
        },
        {
            title: "Back-End Developer",
        },
        {
            title: "UI/UX Designer",
        },
        {
            title: "Videographer",
        },
        {
            title: "Software Developer",
        },
        {
            title: "Mobile Developer",
        },
        {
            title: "Photographer",
        },
        {
            title: "Graphic Designer",
        },
        {
            title: "Web Developer",
        },
        {
            title: "Web Designer",
        },
    ]

    return (
        <div className='WorkSearchComponent' >

            <h className="WorkTitle" >Sana <h className="WorkTitleInside">en uygun</h> pozisyonu bul</h>

            <div className='SearchComponent' >
                <input className='Search Search1' placeholder='Pozisyon veya Anahtar Kelime' onChange={(e) => { setSearchPosition(e.target.value) }} value={searchPosition} />
                <div className='SearchAraCizgi' />
                <input className='Search Search2' placeholder='Konum' color='#301934' />
                <button className='SearchButton' >
                    <img className='SearchButtonIcon' alt="SearchIcon" src={SearchIcon} />
                    ARA</button>
            </div>

            <h className="KeywordsTitle" >En çok arananlar</h>

            <div className='KeywordsArea' >
                {data.map((item, index) => {
                    return (
                        <div className='Keywords' key={index} onClick={() => { setSearchPosition(item.title) }} >
                            <img className='KeywordsButtonIcon' alt="Keywords" src={SearchIcon} />
                            <h className="KeywordsButtonText" >{item.title}</h>
                        </div>
                    )
                }
                )}
            </div>

        </div>
    )
}
