import React from "react";
import "./InputRadioButton.scss";

export default function InputRadioButton(props) {
  return (
    <div className="checkbox">
      <input
        type="checkbox"
        onClick={(e) => {
          props.setCheck(!props.check)
        }}
      />
      <span className={props.radioStyle} >{props.text}</span>
    </div>
  );
}
