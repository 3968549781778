import React, { useState } from 'react'
import optionsButton from "../../../../assets/icons/ellipsis-horizontal-outline.svg"

export default function WorkApplicationCard(props) {
    const [hide, setHide] = useState(false)
    return (
        <div className="WorkApplicationCard"  >
            <div className='WorkApplicationCard2' >
                <img src={props.BusinessLogo} className="WorkApplicationBusinessLogo" alt="Sirket-adi" />
                <div className="WorkApplicationInfo">
                    <h className="WorkApplicationTitle WorkApplicationBusinessName " >{props.sirketAdi}</h>
                    <h className="WorkApplicationTitle WorkApplicationPositionName" >{props.pozisyonAdi}</h>
                    <h className="WorkApplicationTitle WorkApplicationLocation" >{props.konum}</h>
                    <h className="WorkApplicationTitle WorkApplicationStatus" >{props.durum ? "İlan sahibi tarafından başvurunuz incelendi." : "İlan sahibi tarafından başvurunuz henüz incelenmedi"}</h>
                </div>
                <img src={optionsButton} alt="options" className='WorkApplicationOptions' onMouseUp={() => { hide ? setHide(false) : setHide(true) }} onBlur={() => { setHide(false) }} />

                {hide ? <div className='WorkApplicationOptionsMenu' >
                    <h className="WorkApplicationOptionsMenuTitle" >İlanı paylaş</h>
                    <h className="WorkApplicationOptionsMenuTitle" >İlan detayı</h>
                </div> : null}

            </div>
            <div className='WorkApplicationAltCizgi' />
        </div>
    )
}
