import React from "react";
import "./input.scss";

export default function InputComponent(props) {
  return (
    <div className="inputComponent">
      <label for="" className={props.LabelClassName}>
        {props.label}
      </label>
      <input
        className={props.inputClassName}
        type={props.type}
        error={props.error}
        id={props.id}
        placeholder={props.placeHolder}
        onChange={props.onChange}
        maxLength={props.maxLength}
      />
    </div>
  );
}
