import React from "react";
import "./WorkAlertComponent.scss";

import WorkAlertCard from "./WorkAlertCard/WorkAlertCard";

export default function WorkAlertComponent() {
  const data = [
    {
      id: 1,
      konum: "İstanbul (Avrupa)",
      pozisyon: "Yazılım Geliştirici",
      bildirimSikligi: "E-posta yoluyla günlük",
      benzerBildirim: true,
    },
    {
      id: 2,
      konum: "İstanbul (Anadolu)",
      pozisyon: "Front End Developer",
      bildirimSikligi: "E-posta yoluyla günlük",
      benzerBildirim: true,
    },
  ];

  return (
    <div className="WorkApplicationComponent">
      <div className="WorkApplication">
        <h className="ProfileTitle">İş Alarmları</h>
        <h className="ProfileTitleDesc">
          İş fırsatlarını kaçırma diye senin için burada listeledik.
        </h>
        <div className="PersonelInformationAltCizgi AltCizgiUzun" />
      </div>

      {data.map((item, index) => {
        return (
          <WorkAlertCard
            konum={item.konum}
            pozisyonAdi={item.pozisyon}
            bildirimSikligi={item.bildirimSikligi}
            durum={item.benzerBildirim}
          />
        );
      })}
    </div>
  );
}
