import React, { useState, useEffect } from "react";
import "./WorkSearchScreen.scss";

//Components
import TopBar from "../../components/headerComponent/TopBar";
import TopBarAuth from "../../components/headerComponent/TopBarAuth";
import JobAdvertisement from "../../components/jobAdvertisementComponent/JobAdvertisement";
import Popup from "reactjs-popup";

import SearchIcon from "../../assets/icons/search-outline.svg";
import Autocomplete from "react-autocomplete";
import WorkSearchFilterComponent from "../../components/workSearchFilterComponent/WorkSearchFilterComponent";
import Footer from "../../components/footerComponent/footer";

//Assets
import Platin from "../../assets/logo/platinlogo.png";
import CMN from "../../assets/logo/cmnlogopurple.png";

import { useSelector } from "react-redux";
import axios from "axios";

export default function WorkSearchScreen() {
  const auth = useSelector((state) => state.auth);

  const [searchPosition, setSearchPosition] = useState("");
  const [location, setLocation] = useState("");

  const [workData, setWorkData] = useState([]);

  const windowWidth = window.innerWidth;

  useEffect(() => {
    axios
      .get("https://api.esitcv.com/api/GetAdvertsWithoutAuthorize/getAll", {
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          isActive: true,
          isDeleted: false,
          isAscending: false,
          currentPage: 1,
          pageSize: 20,
        },
      })
      .then((res) => {
        console.log(res.data.data);
        setWorkData(res.data.data.advertGetDtos);
      });
  }, []);

  return (
    <div className="WorkSearchScreen">
      <h1 className="reklamText ">
        Çalışan mı arıyorsun?{" "}
        <a className="reklamText bold " rel="nofollow" href="/">
          Hemen ilan ver!
        </a>
      </h1>
      {auth.user != null ? <TopBarAuth /> : <TopBar />}

      <div className="WorkSearchScreenComponent">
        <div className="SearchComponents">
          <Autocomplete
            getItemValue={(item) => item.label}
            items={[]}
            autoHighlight
            sortItems={(a) => a.label}
            wrapperStyle={{
              borderWidth: "0px",
            }}
            inputProps={{
              className: "WorkSearchInput WorkSearchInput1",
              placeholder: "Pozisyon veya Anahtar Kelime",
            }}
            renderItem={(item, isHighlighted) => (
              <div
                style={{
                  background: isHighlighted ? "#421c6d" : "#FFFF",
                  color: isHighlighted ? "white" : "#421c6d",
                  fontSize: windowWidth > 800 ? "1vw" : "4vw",
                  fontFamily: "sans-serif",
                  borderRadius: "5px",
                  paddingLeft: windowWidth > 800 ? "1vw" : "3vw",
                  paddingTop: windowWidth > 800 ? "0.7vw" : "1.2vw",
                  paddingBottom: windowWidth > 800 ? "0.7vw" : "1.2vw",
                  zIndex: 99999,
                }}
              >
                {item.label}
              </div>
            )}
            value={searchPosition}
            onChange={(e) => setSearchPosition(e.target.value)}
            onSelect={(e) => setSearchPosition(e)}
          />

          <div className="SearchAraCizgi" />
          <Autocomplete
            getItemValue={(item) => item.label}
            items={[]}
            sortItems={(a) => a.label}
            wrapperStyle={{
              borderWidth: "0px",
            }}
            inputProps={{
              className: "WorkSearchInput WorkSearchInput2",
              placeholder: "Konum",
            }}
            renderItem={(item, isHighlighted) => (
              <div
                style={{
                  background: isHighlighted ? "#421c6d" : "white",
                  color: isHighlighted ? "white" : "#421c6d",
                  fontSize: windowWidth > 800 ? "1vw" : "4vw",
                  fontFamily: "sans-serif",
                  borderRadius: "5px",
                  paddingLeft: windowWidth > 800 ? "1vw" : "3vw",
                  paddingTop: windowWidth > 800 ? "0.7vw" : "1.2vw",
                  paddingBottom: windowWidth > 800 ? "0.7vw" : "1.2vw",
                }}
              >
                {item.label}
              </div>
            )}
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            onSelect={(e) => setLocation(e)}
          />
          <button className="SearchButtons">
            <img
              className="SearchButtonIcons"
              alt="SearchIcon"
              src={SearchIcon}
            />
            ARA
          </button>
        </div>
      </div>

      <div className="WorkSearchArea">
        <WorkSearchFilterComponent />

        <div style={{ display: "flex", flexDirection: "column" }}>
          {workData.map((item) => {
            return (
              <Popup
                modal="true"
                contentStyle={{
                  width: "65%",
                  height: "auto",
                  top: "1vw",
                  bottom: "1vw",
                  borderRadius: 20,
                }}
                overlayStyle={{ overflowY: "scroll" }}
                lockScroll={true}
                trigger={
                  <button className="JobAdvertisementComponent">
                    <div className="JobAdvertisementLogoStroke">
                      <img
                        src={item.logo}
                        className="JobAdvertisementLogo"
                        alt="Business logo"
                      />
                    </div>
                    <div className="JobAdvertisementCompanyInfo">
                      <h1 className="JobAdvertisementCompanyTitle">
                        {item.companyName}
                      </h1>
                      <h1 className="JobAdvertisementPositionTitle">
                        {item.advert_Information != null
                          ? item.advert_Information.position
                          : "Belirtilmedi"}
                      </h1>
                      <h1 className="JobAdvertisementCompanyLocation">
                        {item.advert_Information != null
                          ? item.advert_Information.jobLocation
                          : "Belirtilmedi"}
                      </h1>
                    </div>
                    <div className="JobAdvertisementButton">
                      {item.advert_Information != null &&
                      item.advert_Information.isRemote
                        ? "Uzaktan Çalışma"
                        : "İş Yerinde"}
                    </div>
                  </button>
                }
                position="center"
              >
                <div className="Work-Search-Detail">
                  <div className="JobAdvertisementPopupLogoStroke">
                    <img
                      src={item.logo}
                      className="JobAdvertisementPopupLogo"
                      alt="Business logo"
                    />
                  </div>
                  <div className="JobAdvertisementCompanyInfo">
                    <h1 className="JobAdvertisementCompanyTitle">
                      {item.companyName}
                    </h1>
                    <h1 className="JobAdvertisementPositionTitle">
                      {item.advert_Information != null
                        ? item.advert_Information.position
                        : "Belirtilmedi"}
                    </h1>
                    <h1 className="JobAdvertisementCompanyLocation">
                      {item.advert_Information != null
                        ? item.advert_Information.jobLocation
                        : "Belirtilmedi"}
                    </h1>
                  </div>
                  <div className="JobAdvertisementCompanyDetail">
                    <h1 className="JobAdvertisementCompanyAddress">
                      {item.advert_Information != null
                        ? item.advert_Information.jobLocation
                        : "Belirtilmedi"}
                    </h1>
                    <h1 className="JobAdvertisementCompanyWorker">
                      {
                        //10-50 çalışan
                      }
                    </h1>
                  </div>
                </div>
                <div className="JobAdvertiser">
                  <h3>İlanı Veren</h3>
                </div>
                <div className="Work-Search-Popup-Detail">
                  <div className="JobAdvertiserLogoStroke">
                    <img
                      src={item.logo}
                      className="JobAdvertiserLogo"
                      alt="Advertiser logo"
                    />
                  </div>
                  <div className="JobAdvertisementCompanyInfo">
                    <h1 className="JobAdvertiserPosition">
                      CMNSoftware Şirketinde Project Manager
                    </h1>
                    <h1 className="JobAdvertiserName">
                      {item.employer != null
                        ? item.employer.name + " " + item.employer.surname
                        : "Belirtilmedi"}
                    </h1>
                  </div>
                  <div className="JobAdvertisementCompanyDetail">
                    <h1 className="JobAdvertisementAdvertiser">
                      {item.employer != null
                        ? item.employer.name +
                          " " +
                          item.employer.surname +
                          " tarafından"
                        : "Belirtilmedi"}
                    </h1>
                    <h1 className="JobAdvertisementAdvertiserTime">
                      {item.createTimeInfo}
                    </h1>
                  </div>
                </div>
                <div className="JobAdvertiserDetail">
                  <p>
                    {" "}
                    {item.advert_Information != null
                      ? item.advert_Information.jobDescription
                      : "Belirtilmedi"}
                  </p>
                </div>
                <div className="JobAdvertiserButtons">
                  <div className="JobAdvertApplyButton">Başvur</div>
                  {/* <div className="JobAdvertSaveButton">İlanı Kaydet</div> */}
                </div>
              </Popup>
            );
          })}
        </div>

        {auth.user != null ? (
          <div
            className="LoginComponent"
            style={{ visibility: "hidden" }}
          ></div>
        ) : (
          <div className="LoginComponent">
            <h3 className="LoginComponentTitle">Hala Kayıtlı Değil Misin?</h3>
            <h5 className="LoginComponentDesc">
              Binlerce iş teklifini değerlendirmek için hemen kayıt ol!
            </h5>
            <button className="LoginComponentButton">Kayıt Ol</button>
            <div className="LoginComponentLine" />
            <h5 className="LoginComponentDesc">Hesabın var mı?</h5>
            <button className="LoginComponentButton">Giriş Yap</button>
          </div>
        )}
      </div>

      <Footer />
    </div>
  );
}
