import React, { useState } from "react";

import "./SignInScreen.scss";
//Components
import InputComponent from "../../components/inputComponent/input";
import InputButtonComponent from "../../components/inputButtonComponent/inputButton";
import GoogleButton from "../../components/GoogleButtonComponent/GoogleButton";
//Icons
import MailIcon from "../../assets/icons/mail-outline.svg";
import PasswordIcon from "../../assets/icons/lock-closed-outline.svg";
import GoogleIcon from "../../assets/icons/logo-google.svg";
import EsitCvLogo from "../../assets/icons/esitcv.png";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../redux/Auth/actions";
import { CircularProgress, Snackbar, Stack } from "@mui/material";
import LoadingComponent from "../../components/loadingComponent/loadingComponent";

export default function SignInScreen() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState([]);

  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);
  console.log(auth.error);

  let navigation = useNavigate();

  const validate = () => {
    let isValid = true;

    if (email.length < 4) {
      handleError("Email adresiniz 3 karakterden az olamaz", "email");
      isValid = false;
    }
    if (password.length < 8) {
      handleError("Şifreniz en az 8 karakterden az olamaz", "password");
      isValid = false;
    }

    if (isValid) {
      login();
      console.log("basarılı");
    } else {
      handleClick();
    }
  };

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleError = (error, input) => {
    setErrors((prevState) => ({ ...prevState, [input]: error }));
  };

  const login = () => {
    dispatch(loginUser(email, password, navigation));
  };

  return (
    <div className="signin">
      <div className="signin-square-left">
        <div className="signin-text-field">
          <div className="signin-text-up">
            <span className="signin-text-up-span">eşitCV</span>
          </div>
          <div className="signin-text-down">
            <span className="signin-text-down-span">Eşit Bir Gelecek</span>
          </div>
        </div>
      </div>
      <div className="signin-square-right">
        <div className="signin-logo">
          <img src={EsitCvLogo}></img>
        </div>
        <div className="signin-texts">
          <h2 className="signin-texts-h2">Tekrar hoş geldin!</h2>
          <span className="signin-texts-span">
            Hemen giriş yapıp aramıza yeniden katıl!
          </span>
        </div>
        <form className="signin-form">
          <InputComponent
            type="text"
            inputClassName="input"
            id="email"
            placeholder=" "
            label="E-Posta"
            LabelClassName="label"
            onChange={(e) => setEmail(e.target.value)}
            error={errors.email}
          />

          <InputComponent
            type="password"
            inputClassName="input"
            id="password"
            placeholder=" "
            label="Şifre"
            LabelClassName="label"
            onChange={(e) => setPassword(e.target.value)}
            error={errors.password}
          />
        </form>
        <div className="signin-btn-field">
          <button
            className="signin-btn"
            onClick={() => {
              validate();
            }}
          >
            Giriş Yap
          </button>
        </div>
      </div>
      {auth.isLoading ? <LoadingComponent /> : null}

      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message={errors.email}
      />
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message={errors.password}
      />
      {auth.error && (
        <Snackbar
          open={true} 
          autoHideDuration={3000}
          onClose={handleClose}
          message={auth.error}
        />
      )}
    </div>
  );
}
