import React from 'react'
import './SignUpStepThree.scss'

//Components
import InputComponent from "../../../components/inputComponent/input";


//icons
import uploadPhotoImage from "../../../assets/signupImage/Unsplash.svg"
import { Link } from 'react-router-dom';
export default function SignUpStepThree() {
    return (
        <div className='SignUpStepThree'>
            <div className='square-left-signup-stepthree'>
                <div className='title-field-signup-stepthree'>
                    <div className='first-title-field-signup-stepthree'>
                        <div className='first-icon-signup-stepthree'>
                        </div>
                        <div className='first-title-signup-stepthree'>Profil Seçimi</div>
                        <div className='line-signup-stepthree'></div>
                    </div>
                    <div className='second-title-field-signup-stepthree'>
                        <div className='second-icon-signup-stepthree'>
                        </div>
                        <div className='second-title-signup-stepthree'>Profil Oluşturma</div>
                        <div className='line-signup-stepthree'></div>
                    </div>
                </div>

            </div>
            <div className='square-right-signup-stepthree'>
                <div className='text-field-signup-stepthree'>
                    <div className='text-one-signup-stepthree'>Herkese açık şirket profilinizi oluşturalım</div>
                    <div className='text-two-signup-stepthree'>Logonuzu, şirket sektörünü ve sloganınızı ekleyin.</div>
                </div>
                <div className='upload-photo-field-signup-stepthree'>
                    <img src={uploadPhotoImage} />
                </div>
                <div className='give-company-sector-signup-stepthree'>
                    <InputComponent
                        type="text"
                        inputClassName="small-input"
                        id="name"
                        placeholder='Sektör' />
                </div>
                <div className='give-company-slogan-signup-stepthree'>
                    <textarea
                        id='description'
                        rows="10"
                        cols="25"
                        placeholder='-damnatio memoriae-'
                    ></textarea>
                </div>
                <div className='button-field-signup-stepthree'>
                <Link to="/signUpStepFour" exact><button
                            className='btn-signup-stepone'>İleri</button></Link>
                </div>
               
            </div>
        </div>
    )
}
