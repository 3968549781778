import React from "react";

import NotFoundImage from "../../assets/404Image/404.svg";

import "./NotFoundScreen.scss";

export default function NotFoundScreen() {
  return (
    /*
    <div className='NotFound'>
        <div className='NotFoundText'><h1>WOAĞ! SAYFA BULUNAMADI</h1></div>
        <div className='NotFoundBG'><img src={NotFoundImage} alt='BG Image'/></div>
        <div className='NotFoundButton'><button><a href='/'>ANASAYFAYA DÖN</a></button></div>
    </div>
    */
    <div className="NotFound">
      <h1 className="NotFoundText">ÜZGÜNÜM! SAYFA BULUNAMADI</h1>
      <img className="NotFoundBG" src={NotFoundImage} alt="Image" />
      <button className="NotFoundButton">
        <a href="/">ANASAYFAYA DÖN</a>
      </button>
    </div>
  );
}
