import React from 'react'
import "./SliderComponent.scss"

import { Link } from 'react-router-dom'
/* eslint-disable */
export default function SliderComponent() {

    return (
        <div className='SliderBackground'  >
            <h className="MiniTitle" >Müşteriler için</h>
            <h className="Title" >Sana en uygun pozisyonu bul.</h>
            <h className="TitleDesc" >En büyük profesyoneller ağıyla çalışın ve hızlı geri dönüşlerden büyük dönüşümlere kadar her şeyi halledin.</h>

            <div className='FindAreaComponent' >
                <div className='FindArea' >
                    <h1 className='FindAreaTitle'  >İş başvurunu paylaş ve iş bul.</h1>
                    <Link to="/">   <a className='FindAreaNav'  >İlan ara</a></Link>
                </div>

                <div className='FindArea' >
                    <h1 className='FindAreaTitle'  >Projeleri keşfet veya satın al.</h1>
                    <Link to="/">  <a className='FindAreaNav'>Projeler platformu</a></Link>
                </div>

                <div className='FindArea' >
                    <h1 className='FindAreaTitle'  >Yeteneğini bulmanda yardımcı olalım.</h1>
                    <Link to="/"><a className='FindAreaNav'>Yetenek avcısı</a></Link>
                </div>

            </div>

        </div>
    )
}
