import React, { useState } from "react";

import "./SettingsScreen.scss";

import TopBar from "../../components/headerComponent/TopBar";
import TopBarAuth from "../../components/headerComponent/TopBarAuth";
import Footer from "../../components/footerComponent/footer";
import ProfileInformationComponent from "../../components/settingsComponent/profileInformationComponent/ProfileInformationComponent";
import AccountAccessComponent from "../../components/settingsComponent/accountAccessComponent/AccountAccessComponent";
import SettingsContactComponent from "../../components/settingsComponent/settingsContactComponent/SettingsContactComponent";
import DataSecurityComponent from "../../components/settingsComponent/dataSecurityComponent/DataSecurityComponent";
import { useSelector } from "react-redux";

export default function SettingsScreen() {
  const auth = useSelector(state => state.auth);

  const [screen, setScreen] = useState("profile-information");
  const [menu, setMenu] = useState("0");
  return (
    <div className="headerandfooter">
      <h1 className="reklamText ">
        Çalışan mı arıyorsun?{" "}
        <a className="reklamText bold " rel="nofollow" href="/">
          Hemen ilan ver!
        </a>
      </h1>

      {auth.user != null ? <TopBarAuth /> : <TopBar />}

      <div className="SettingsScreen">
        <div className="SettingsNavigator">
          <ul className="SettingsNavigatorComponent">
            <li
              className="SettingsNavigatorTitle"
              onClick={() => {
                setScreen("profile-information");
              }}
            >
              <div
                class="SettingsDropDown"
                onClick={() => {
                  setMenu("0");
                }}
              >
                <label for="hesaptercihleri">Hesap Tercihleri</label>
                <div className="SettingsNavigatorAltCizgi" />
                {menu === "0" ? (
                  <div class="SettingsDropDownItem">
                    <li>Profil Bilgileri</li>
                    <li>Site Tercihleri</li>
                    <li>Hesap Yönetimi</li>
                  </div>
                ) : (
                  console.log(menu)
                )}
              </div>
            </li>

            <li
              className="SettingsNavigatorTitle"
              onClick={() => {
                setScreen("guvenlik");
              }}
            >
              <div
                class="SettingsDropDown"
                onClick={() => {
                  setMenu("1");
                }}
              >
                <label for="guvenlik">Güvenlik</label>
                <div className="SettingsNavigatorAltCizgi" />
                {menu === "1" ? (
                  <div class="SettingsDropDownItem">
                    <li>Hesap Erişimi</li>
                  </div>
                ) : (
                  console.log(menu)
                )}
              </div>
            </li>

            <li
              className="SettingsNavigatorTitle"
              onClick={() => {
                setScreen("iletisim");
              }}
            >
              <div
                class="SettingsDropDown"
                onClick={() => {
                  setMenu("2");
                }}
              >
                <label for="iletisim">İletişim</label>
                <div className="SettingsNavigatorAltCizgi" />
                {menu === "2" ? (
                  <div class="SettingsDropDownItem">
                    <li>Bildirim Tercihleri</li>
                    <li>İletişim Tercihleri</li>
                    <li>Mesajlaşma Yönetimi</li>
                  </div>
                ) : (
                  console.log(menu)
                )}
              </div>
            </li>

            <li
              className="SettingsNavigatorTitle"
              onClick={() => {
                setScreen("veriler-ve-gizlilik");
              }}
            >
              <div
                class="SettingsDropDown"
                onClick={() => {
                  setMenu("3");
                }}
              >
                <label for="verilervegizlilik">Veriler ve Gizlilik</label>
                <div className="SettingsNavigatorAltCizgi" />
                {menu === "3" ? (
                  <div class="SettingsDropDownItem">
                    <li>Veri Kullanımı</li>
                    <li>İş Arama Tercihleri</li>
                    <li>3.Taraf Uygulamalar</li>
                  </div>
                ) : (
                  console.log(menu)
                )}
              </div>
            </li>
          </ul>
        </div>

        <div className="SettingsPages">
          {screen === "profile-information" ? (
            <ProfileInformationComponent />
          ) : screen === "guvenlik" ? (
            <AccountAccessComponent />
          ) : screen === "iletisim" ? (
            <SettingsContactComponent />
          ) : screen === "veriler-ve-gizlilik" ? (
            <DataSecurityComponent />
          ) : (
            console.log(screen)
          )}
        </div>
      </div>
      <div className="headerandfooter">
        <Footer />
      </div>
    </div>
  );
}
