import React, { useState } from 'react'
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";

export default function SavedWorksCard(props) {
    const [hide, setHide] = useState(false)
    return (
        <div className="WorkApplicationCard"  >
            <div className='WorkApplicationCard2' >
                <div className="WorkApplicationInfo">
                    <h className="WorkApplicationTitle WorkApplicationLocation" >{props.konum}</h>
                    <h className="WorkApplicationTitle WorkApplicationPositionName" >{props.pozisyonAdi}</h>
                    <h className="WorkApplicationTitle WorkApplicationStatus" >Sıklık: {props.bildirimSikligi ? "Evet." : "Hayır"}</h>
                    <h className="WorkApplicationTitle WorkApplicationStatus" >Benzer iş ilanları için bildirim alın: {props.durum ? "Evet." : "Hayır"}</h>
                </div>
                <div className='SavedWorksOptionsComponent'>
                    <FaPencilAlt className='SavedWorkOptions' onMouseUp={() => { hide ? setHide(false) : setHide(true) }} onBlur={() => { setHide(false) }} />
                    <FaTrashAlt className='SavedWorkOptions' onMouseUp={() => { hide ? setHide(false) : setHide(true) }} onBlur={() => { setHide(false) }} />
                </div>

                {hide ? <div className='WorkApplicationOptionsMenu' >
                    <h className="WorkApplicationOptionsMenuTitle" >İlanı paylaş</h>
                    <h className="WorkApplicationOptionsMenuTitle" >İlan detayı</h>
                </div> : null}

            </div>
            <div className='SavedWorksAltCizgi' />
        </div>
    )
}
