import React from "react";
import "./footer.scss";

//Logo
import PlatinLogo from "../../assets/logo/platin.png";

//Social Media Icons
import Facebook from "../../assets/icons/facebook.svg";
import Twitter from "../../assets/icons/twitter.svg";
import Instagram from "../../assets/icons/instagram.svg";
import LınkedIn from "../../assets/icons/linkedin.svg";

export default function Footer() {
  return (
    <div className="footer">
      <div className="Routes">
        <div className="FooterMenuLeft">
          <ul>
            <li><h3>İş Arayanlar İçin</h3></li>
            <li><a href="#">İş Ara</a></li>
            <li><a href="#">Kariyer Hedefi</a></li>
            <li><a href="#">Şirket Profili</a></li>
            <li><a href="#">Servisler</a></li>
            <li><a href="#">Yardım</a></li>
          </ul>
        </div>
        <div className="FooterMenuMiddle">
          <ul>
            <li><h3>İşverenler İçin</h3></li>
            <li><a href="#">Ürünler</a></li>
            <li><a href="#">Çözümler</a></li>
            <li><a href="#">Fiyatlandırma</a></li>
            <li><a href="#">Kaynaklar</a></li>
            <li><a href="#">Yardım</a></li>
          </ul>
        </div>
        <div className="FooterMenuRight">
          <ul>
            <li><h3>Kurumsal</h3></li>
            <li><a href="#">Yararlı Kaynaklar</a></li>
            <li><a href="#">EşitCV Hakkında</a></li>
            <li><a href="#">EşitCV İle Çalışmak İçin</a></li>
            <li><a href="#">Kullanım Şartları</a></li>
            <li><a href="#">Gizlilik Merkezi</a></li>
            <li><a href="#">Güvenlik Merkezi</a></li>
            <li><a href="#">Erişilebilirlik Merkezi</a></li>
            <li><a href="#">Reklam Seçenekleri</a></li>
          </ul>
        </div>
      </div>
      <div className="hr" />
      <div className="SocialMedia">
        <img src={Facebook} className="SocialMediaIcon" alt={Facebook} />
        <img src={Twitter} className="SocialMediaIcon" alt={Twitter} />
        <img src={Instagram} className="SocialMediaIcon" alt={Instagram} />
        <img src={LınkedIn} className="SocialMediaIcon" alt={LınkedIn} />
      </div>
      <div className="hr" />
      <div className="FooterLogo">
        <img src={PlatinLogo} className="PlatinLogo" alt={PlatinLogo} />
      </div>
      <div className="Copyright">
        <h7>© Copyright All Right Reserved. CMNSoftware</h7>
      </div>
    </div>
  );
}
