import { CircularProgress } from "@mui/material";
import React from "react";

export default function LoadingComponent() {
  return (
    <div
      style={{
        position: "absolute",
        display: "flex",
        width: "102%",
        height: "103%",
        backgroundColor: "rgba(0,0,0,0.5)",
        margin: 0,
        zIndex: 9999,
        justifyContent: "center",
        marginLeft: "-2vw",
        marginTop: "-2vw",
      }}
    >
      <CircularProgress
        color="error"
        sx={{
          position: "absolute",
          top: "50%",
          left: "49%",
          transform: "translate(-50%, -50%)",
        }}
      />
    </div>
  );
}
