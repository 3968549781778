import React from 'react'

import "./AdviceComponent.scss";

//images
import gelecegeyatirim from '../../assets/advice/gelecegeyatirim.png'
import ozgecmisinonemi from '../../assets/advice/ozgecmisinonemi.png'
import kararlilik from '../../assets/advice/kararlilik.png'

export default function Advice() {
  return (
    <div className='Advice'>
      <div className='AdviceTitles'>
        <h className='AdviceTitle'>Kariyer <h className='AdviceTitleInside'>Yoldaşı</h></h>
        <h className='AdviceMore'>Daha Fazlası...</h>
      </div>
      <div className='AdviceCards'>
        <div className='AdviceCard'>
          <h3 className='AdviceCardText'>Geleceğe Yatırım</h3>
          <img src={gelecegeyatirim} alt='Geleceğe Yatırım' />
        </div>
        <div className='AdviceCard'>
          <h3 className='AdviceCardText'>Özgeçmişin Önemi</h3>
          <img src={ozgecmisinonemi} alt='Özgeçmişin Önemi' />
        </div>
        <div className='AdviceCard'>
          <h3 className='AdviceCardText'>Kararlılık</h3>
          <img src={kararlilik} alt='Kararlılık' />
        </div>
      </div>
    </div>
  )
}
