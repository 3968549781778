import React from 'react'

import "./SettingsContactComponent.scss";

export default function SettingsContactComponent() {
  return (
    <div className="ProfileInformationComponent">
      <div className="ProfileInformation">
        <h1>Bildirim Tercihleri</h1>

        <div className="ProfileInformationLine" />
        <div className="ProfileInformationTitle">
          <h3>EşitCV'de</h3>
          <div className="ProfileInformationDescription">
            <h5>EşitCV web ve mobil uygulamasından bildirim alma ayarlarınız</h5>
          </div>
          <div className="ProfileInformationButton">
            <a href="">Değiştir</a>
          </div>
        </div>

        <div className="ProfileInformationLine" />
        <div className="ProfileInformationTitle">
          <h3>E-Posta</h3>
          <div className="ProfileInformationDescription">
            <h5>Tercih ettiğiniz uyarıları seçtiğiniz e-posta adreslerini alma ayarlarınız</h5>
          </div>
          <div className="ProfileInformationButton">
            <a href="">Değiştir</a>
          </div>
        </div>

        <div className="ProfileInformationLine" />
        <div className="ProfileInformationTitle">
          <h3>Anlık Bildirim</h3>
          <div className="ProfileInformationDescription">
            <h5>Anlık bildirim ayarlarınız</h5>
          </div>
          <div className="ProfileInformationButton">
            <a href="">Değiştir</a>
          </div>
        </div>
      </div>

      <div className="ProfileInformationLine" />
      <div className="ProfileInformation">
        <h1>İletişim Tercihleri</h1>
        <div className="ProfileInformationLine" />
        <div className="ProfileInformationTitle">
          <h3>Sizinle İletişim Kurabilecek Kanallar</h3>
          <div className="ProfileInformationDescription">
            <h5>Kimlerin sizinle nasıl iletişime geçeceğini ayarlayın.</h5>
          </div>
        </div>
      </div>
      
      <div className="ProfileInformationLine" />
      <div className="ProfileInformation">
        <h1>Mesajlaşma Tercihleri</h1>
        <div className="ProfileInformationLine" />
        <div className="ProfileInformationTitle">
          <h3>Okundu ve Son Görülme Göstergeleri</h3>
          <div className="ProfileInformationDescription">
            <h5>Okundu ve son görülme göstergelerini etkinleştirin</h5>
          </div>
        </div>
      </div>

    </div>
  )
}
