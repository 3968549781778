import React from "react";
import "./WorkSearchFilterComponent.scss";

import Autocomplete from "react-autocomplete";
import Checkbox from "@mui/material/Checkbox";

export default function WorkSearchFilterComponent() {
  const [inputCountry, setInputCountry] = React.useState();
  const [inputIl, setInputIl] = React.useState();
  const [inputIlce, setInputIlce] = React.useState();

  const [inputSektor, setInputSektor] = React.useState();
  const [inputPozisyon, setInputPozisyon] = React.useState();
  const [inputEgitimSeviyesi, setInputEgitimSeviyesi] = React.useState();
  const [inputDil, setInputDil] = React.useState();

  const optionsUlke = [
    {
      label: "Türkiye",
    },
  ];

  const optionsIl = [
    {
      label: "İstanbul",
    },
  ];

  const optionsIlce = [
    {
      label: "Adalar",
    },
  ];

  const ilanTarihi = [
    { id: 0, code: "enyeniilan", label: "En Yeni İlan", checked: true },
    { id: 1, code: "son1saat", label: "Son 1 saat", checked: false },
    { id: 2, code: "son6saat", label: "Son 6 saat", checked: false },
    { id: 3, code: "son12saat", label: "Son 12 saat", checked: false },
    { id: 4, code: "son1hafta", label: "Son 1 hafta", checked: false },
    { id: 5, code: "son1ay", label: "Son 1 ay", checked: false },
  ];

  const deneyimSuresi = [
    { id: 0, code: "tumu", label: "Tümü", checked: true },
    { id: 1, code: "deneyimli", label: "Deneyimli", checked: false },
    { id: 2, code: "deneyimsiz", label: "Deneyimsiz", checked: false },
  ];

  const sektor = [
    { id: 1, label: "Bilişim" },
    { id: 2, label: "Yazılım" },
    { id: 3, label: "Eğitim" },
    { id: 4, label: "Teknoloji" },
    { id: 5, label: "E-ticaret" },
    { id: 6, label: "Sağlık" },
    { id: 7, label: "Gıda" },
    { id: 8, label: "Giyim" },
    { id: 9, label: "Moda" },
  ];

  const pozisyon = [
    { id: 1, label: "Üst düzey yönetici" },
    { id: 2, label: "Yönetici" },
    { id: 3, label: "Üst düzey müdür" },
    { id: 4, label: "Müdür" },
    { id: 5, label: "Uzman" },
    { id: 6, label: "Yeni Başlayan" },
  ];

  const dil = [
    { id: 1, label: "Türkçe" },
    { id: 2, label: "İngilizce" },
    { id: 3, label: "Fransızca" },
    { id: 4, label: "İtalyanca" },
    { id: 5, label: "Almanca" },
    { id: 6, label: "Rusça" },
    { id: 7, label: "Arapça" },
    { id: 8, label: "İspanyolca" },
    { id: 9, label: "İsveççe" },
    { id: 10, label: "Macarca" },
    { id: 11, label: "Azerice" },
    { id: 12, label: "İrlandaca" },
    { id: 13, label: "Portekizce" },
  ];

  const egitimSeviyesi = [
    { id: 1, label: "Lise" },
    { id: 2, label: "Önlisans" },
    { id: 3, label: "Lisans" },
    { id: 4, label: "Yüksek Lisans" },
    { id: 5, label: "Doktora" },
  ];

  const calismaSekli = [
    { id: 0, code: "tumu", label: "Tümü", checked: true },
    { id: 1, code: "tamzamanli", label: "Tam zamanlı", checked: false },
    { id: 2, code: "yarizamanli", label: "Yarı zamanlı", checked: false },
    { id: 3, code: "sozlesmeli", label: "Sözleşmeli", checked: false },
    { id: 4, code: "serbestzamanli", label: "Serbest zamanlı", checked: false },
    { id: 5, code: "uzaktancalisma", label: "Uzaktan çalışma", checked: false },
    { id: 6, code: "stajyer", label: "Stajyer", checked: false },
  ];

  const windowWidth = window.innerWidth;

  return (
    <form className="WorkSearchFilterComponent">
      <div className="WorkSearchFilterArea">
        <h1 className="WorkSearchFilterTitle">Konum</h1>
        <Autocomplete
          getItemValue={(item) => item.label}
          items={optionsUlke}
          autoHighlight
          sortItems={(a) => a.labe}
          wrapperStyle={{
            borderWidth: "0px",
          }}
          inputProps={{
            className: "WorkSearchFilterInput",
            placeholder: "Ülke",
          }}
          value={inputCountry}
          onChange={(e) => setInputCountry(e.target.value)}
          onSelect={(e) => setInputCountry(e)}
          renderItem={(item, isHighlighted) => (
            <div
              style={{
                background: isHighlighted ? "#421c6d" : "white",
                color: isHighlighted ? "white" : "#421c6d",
                fontSize: windowWidth > 800 ? "1vw" : "4vw",
                fontFamily: "sans-serif",
                borderRadius: "5px",
                paddingLeft: windowWidth > 800 ? "1vw" : "3vw",
                paddingTop: windowWidth > 800 ? "0.7vw" : "1.2vw",
                paddingBottom: windowWidth > 800 ? "0.7vw" : "1.2vw",
              }}
            >
              {item.label}
            </div>
          )}
        />
        <div className="WorkSearchFilterLine" />

        <Autocomplete
          getItemValue={(item) => item.label}
          items={optionsIl}
          autoHighlight
          sortItems={(a) => a.labe}
          wrapperStyle={{
            borderWidth: "0px",
          }}
          inputProps={{
            className: "WorkSearchFilterInput",
            placeholder: "İl",
          }}
          value={inputIl}
          onChange={(e) => setInputIl(e.target.value)}
          onSelect={(e) => setInputIl(e)}
          renderItem={(item, isHighlighted) => (
            <div
              style={{
                background: isHighlighted ? "#421c6d" : "white",
                color: isHighlighted ? "white" : "#421c6d",
                fontSize: windowWidth > 800 ? "1vw" : "4vw",
                fontFamily: "sans-serif",
                borderRadius: "5px",
                paddingLeft: windowWidth > 800 ? "1vw" : "3vw",
                paddingTop: windowWidth > 800 ? "0.7vw" : "1.2vw",
                paddingBottom: windowWidth > 800 ? "0.7vw" : "1.2vw",
                zIndex: "99999",
              }}
            >
              {item.label}
            </div>
          )}
        />
        <div className="WorkSearchFilterLine" />

        <Autocomplete
          getItemValue={(item) => item.label}
          items={optionsIlce}
          autoHighlight
          sortItems={(a) => a.labe}
          wrapperStyle={{
            borderWidth: "0px",
          }}
          inputProps={{
            className: "WorkSearchFilterInput",
            placeholder: "İlçe",
          }}
          value={inputIlce}
          onChange={(e) => setInputIlce(e.target.value)}
          onSelect={(e) => setInputIlce(e)}
          renderItem={(item, isHighlighted) => (
            <div
              style={{
                background: isHighlighted ? "#421c6d" : "white",
                color: isHighlighted ? "white" : "#421c6d",
                fontSize: windowWidth > 800 ? "1vw" : "4vw",
                fontFamily: "sans-serif",
                borderRadius: "5px",
                paddingLeft: windowWidth > 800 ? "1vw" : "3vw",
                paddingTop: windowWidth > 800 ? "0.7vw" : "1.2vw",
                paddingBottom: windowWidth > 800 ? "0.7vw" : "1.2vw",
              }}
            >
              {item.label}
            </div>
          )}
        />
        <div className="WorkSearchFilterLine" />
      </div>

      <div className="WorkSearchFilterArea">
        <h1 className="WorkSearchFilterTitle">İlan Tarihi</h1>
        {ilanTarihi.map((item) => {
          return (
            <div className="WorkSearchCheckBoxArea">
              <Checkbox
                onClick={() => {
                  item.checked ? (item.checked = false) : (item.checked = true);
                }}
                className="WorkSearchCheckbox"
                checked={item.checked}
                sx={{
                  color: "#421C6D",
                  "&:hover": {
                    color: "#421C6D",
                  },
                  "&.Mui-checked": {
                    color: "#421C6D",
                  },
                  width: "1vw",
                  height: "1vw",
                }}
              />
              <h1 className="WorkSearchCheckBoxTitle">{item.label}</h1>
            </div>
          );
        })}
      </div>

      <div className="WorkSearchFilterLine" style={{ marginTop: "0.5vw" }} />

      <div className="WorkSearchFilterArea">
        <h1 className="WorkSearchFilterTitle">Deneyim Süresi</h1>
        {deneyimSuresi.map((item) => {
          return (
            <div className="WorkSearchCheckBoxArea">
              <Checkbox
                onClick={() => {
                  item.checked ? (item.checked = false) : (item.checked = true);
                }}
                className="WorkSearchCheckbox"
                checked={item.checked}
                sx={{
                  color: "#421C6D",
                  "&:hover": {
                    color: "#421C6D",
                  },
                  "&.Mui-checked": {
                    color: "#421C6D",
                  },
                  width: "1vw",
                  height: "1vw",
                }}
              />
              <h1 className="WorkSearchCheckBoxTitle">{item.label}</h1>
            </div>
          );
        })}
      </div>

      <div className="WorkSearchFilterLine" style={{ marginTop: "0.5vw" }} />

      <div className="WorkSearchFilterArea">
        <h1 className="WorkSearchFilterTitle">Sektör</h1>
        <Autocomplete
          getItemValue={(item) => item.label}
          items={sektor}
          autoHighlight
          sortItems={(a) => a.labe}
          wrapperStyle={{
            borderWidth: "0px",
          }}
          inputProps={{
            className: "WorkSearchFilterInput",
            placeholder: "Eğitim",
          }}
          value={inputSektor}
          onChange={(e) => setInputSektor(e.target.value)}
          onSelect={(e) => setInputSektor(e)}
          renderItem={(item, isHighlighted) => (
            <div
              style={{
                background: isHighlighted ? "#421c6d" : "white",
                color: isHighlighted ? "white" : "#421c6d",
                fontSize: windowWidth > 800 ? "1vw" : "4vw",
                fontFamily: "sans-serif",
                borderRadius: "5px",
                paddingLeft: windowWidth > 800 ? "1vw" : "3vw",
                paddingTop: windowWidth > 800 ? "0.7vw" : "1.2vw",
                paddingBottom: windowWidth > 800 ? "0.7vw" : "1.2vw",
              }}
            >
              {item.label}
            </div>
          )}
        />
        <div className="WorkSearchFilterLine" />

        <h1 className="WorkSearchFilterTitle">Pozisyon</h1>
        <Autocomplete
          getItemValue={(item) => item.label}
          items={pozisyon}
          autoHighlight
          sortItems={(a) => a.labe}
          wrapperStyle={{
            borderWidth: "0px",
          }}
          inputProps={{
            className: "WorkSearchFilterInput",
            placeholder: "Eğitmen",
          }}
          value={inputPozisyon}
          onChange={(e) => setInputPozisyon(e.target.value)}
          onSelect={(e) => setInputPozisyon(e)}
          renderItem={(item, isHighlighted) => (
            <div
              style={{
                background: isHighlighted ? "#421c6d" : "white",
                color: isHighlighted ? "white" : "#421c6d",
                fontSize: windowWidth > 800 ? "1vw" : "4vw",
                fontFamily: "sans-serif",
                borderRadius: "5px",
                paddingLeft: windowWidth > 800 ? "1vw" : "3vw",
                paddingTop: windowWidth > 800 ? "0.7vw" : "1.2vw",
                paddingBottom: windowWidth > 800 ? "0.7vw" : "1.2vw",
              }}
            >
              {item.label}
            </div>
          )}
        />
        <div className="WorkSearchFilterLine" />

        <h1 className="WorkSearchFilterTitle">Eğitim Seviyesi</h1>
        <Autocomplete
          getItemValue={(item) => item.label}
          items={egitimSeviyesi}
          autoHighlight
          sortItems={(a) => a.labe}
          wrapperStyle={{
            borderWidth: "0px",
          }}
          inputProps={{
            className: "WorkSearchFilterInput",
            placeholder: "Doktora",
          }}
          value={inputEgitimSeviyesi}
          onChange={(e) => setInputEgitimSeviyesi(e.target.value)}
          onSelect={(e) => setInputEgitimSeviyesi(e)}
          renderItem={(item, isHighlighted) => (
            <div
              style={{
                background: isHighlighted ? "#421c6d" : "white",
                color: isHighlighted ? "white" : "#421c6d",
                fontSize: windowWidth > 800 ? "1vw" : "4vw",
                fontFamily: "sans-serif",
                borderRadius: "5px",
                paddingLeft: windowWidth > 800 ? "1vw" : "3vw",
                paddingTop: windowWidth > 800 ? "0.7vw" : "1.2vw",
                paddingBottom: windowWidth > 800 ? "0.7vw" : "1.2vw",
              }}
            >
              {item.label}
            </div>
          )}
        />
        <div className="WorkSearchFilterLine" />

        <h1 className="WorkSearchFilterTitle">Dil</h1>
        <Autocomplete
          getItemValue={(item) => item.label}
          items={dil}
          autoHighlight
          sortItems={(a) => a.labe}
          wrapperStyle={{
            borderWidth: "0px",
          }}
          inputProps={{
            className: "WorkSearchFilterInput",
            placeholder: "Türkçe",
          }}
          value={inputDil}
          onChange={(e) => setInputDil(e.target.value)}
          onSelect={(e) => setInputDil(e)}
          renderItem={(item, isHighlighted) => (
            <div
              style={{
                background: isHighlighted ? "#421c6d" : "white",
                color: isHighlighted ? "white" : "#421c6d",
                fontSize: windowWidth > 800 ? "1vw" : "4vw",
                fontFamily: "sans-serif",
                borderRadius: "5px",
                paddingLeft: windowWidth > 800 ? "1vw" : "3vw",
                paddingTop: windowWidth > 800 ? "0.7vw" : "1.2vw",
                paddingBottom: windowWidth > 800 ? "0.7vw" : "1.2vw",
              }}
            >
              {item.label}
            </div>
          )}
        />
      </div>
      <div className="WorkSearchFilterLine" />

      <div className="WorkSearchFilterArea">
        <h1 className="WorkSearchFilterTitle">Çalışma Şekli</h1>
        {calismaSekli.map((item) => {
          return (
            <div className="WorkSearchCheckBoxArea">
              <Checkbox
                onClick={() => {
                  item.checked ? (item.checked = false) : (item.checked = true);
                }}
                className="WorkSearchCheckbox"
                checked={item.checked}
                sx={{
                  color: "#421C6D",
                  "&:hover": {
                    color: "#421C6D",
                  },
                  "&.Mui-checked": {
                    color: "#421C6D",
                  },
                  width: "1vw",
                  height: "1vw",
                }}
              />
              <h1 className="WorkSearchCheckBoxTitle">{item.label}</h1>
            </div>
          );
        })}
      </div>
      <div className="WorkSearchFilterLine" style={{ marginTop: "0.5vw" }} />
      <button className="WorkSearchFilterButton" onClick={() => {}}>
        Filtreyi Uygula
      </button>
    </form>
  );
}
