import React, { useState } from "react";
import { useSelector } from "react-redux";
import TopBar from "../../components/headerComponent/TopBar";
import TopBarAuth from "../../components/headerComponent/TopBarAuth";
import PersonalInformationComponent from "../../components/profileComponent/personalInformationComponent/PersonalInformationComponent";
import SavedWorksComponent from "../../components/profileComponent/savedWorkComponent/SavedWorksCard";
import WorkAlertComponent from "../../components/profileComponent/workAlertComponent/WorkAlertComponent";
import WorkApplicationComponent from "../../components/profileComponent/workApplicationComponent/WorkApplicationComponent";
import "./ProfileScreen.scss";

export default function ProfileScreen() {
  const auth = useSelector((state) => state.auth);
  const [screen, setScreen] = useState("kisisel-bilgiler");

  return (
    <div>
            <h1 className="reklamText ">
        Çalışan mı arıyorsun?{" "}
        <a className="reklamText bold " rel="nofollow" href="/">
          Hemen ilan ver!
        </a>
      </h1>

      {auth.user != null ? <TopBarAuth /> : <TopBar />}
    <div className="ProfileScreen">
      <div className="ProfileNavigator">
        <ul className="ProfileNavigatorComponent">
          <li
            className="ProfileNavigatorTitle"
            onClick={() => {
              setScreen("kisisel-bilgiler");
            }}
          >
            Kişisel Bilgileriniz
          </li>

          <div className="ProfileNavigatorAltCizgi" />

          <li
            className="ProfileNavigatorTitle"
            onClick={() => {
              setScreen("basvurular");
            }}
          >
            Başvurular
          </li>

          <div className="ProfileNavigatorAltCizgi" />

          {/* <li
            className="ProfileNavigatorTitle"
            onClick={() => {
              setScreen("kaydedilen-ilanlar");
            }}
          >
            Kaydedilen İlanlar
          </li>

          <div className="ProfileNavigatorAltCizgi" /> */}
{/* 
          <li
            className="ProfileNavigatorTitle"
            onClick={() => {
              setScreen("is-alarmlari");
            }}
          >
            İş Alarmları
          </li>

          <div className="ProfileNavigatorAltCizgi" /> */}
        </ul>
      </div>

      <div className="ProfilePages">
        {screen === "kisisel-bilgiler" ? (
          <PersonalInformationComponent />
        ) : screen === "basvurular" ? (
          <WorkApplicationComponent />
        ) : screen === "kaydedilen-ilanlar" ? (
          <SavedWorksComponent />
        ) : screen === "is-alarmlari" ? (
          <WorkAlertComponent />
        ) : (
          console.log(screen)
        )}
      </div>
    </div>
    </div>

  );
}
