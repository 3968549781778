import React from 'react';

//CSS
import "./BusinessAdvertAdd.scss";

//Images
import AdvertAdd from "../../../assets/business/advertadd.png";
import Confetti1 from "../../../assets/business/Confetti1.png";
import Confetti2 from "../../../assets/business/Confetti2.png";

export default function BusinessAdvertAdd() {
  return (
    <div className="BusinessMain">
        <div className="BusinessTopBar">
            <h1>İlan Ekle</h1>
        </div>
        <div className="BusinessMainPages">
            <div className="BusinessPages">
                <div className="BusinessPanelAdvertAdd">
                    <img className='Confetti2' src={Confetti2} alt='Confetti' />
                    <img className='BusinessAdvertIMG' src={AdvertAdd} alt='Advert Add' />
                    <img className='Confetti1' src={Confetti1} alt='Confetti' />
                    <h3>Hemen ilanını oluştur, kısa sürede adaylara ulaş!</h3>
                    <button>Oluştur</button>
                </div>
            </div>
        </div>
    </div>
  )
}
