import React, { useState, useEffect } from "react";
import "./SignUpStepOne.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
//icons
import PeepSittingImage from "../../../assets/signupImage/peep-sitting-14.svg";
import PeepStandingImage from "../../../assets/signupImage/peep-standing-1.svg";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../../../redux/Auth/actions";
import LoadingComponent from "../../../components/loadingComponent/loadingComponent";
import { Snackbar } from "@mui/material";

export default function SignUpStepOne() {
  const navigate = useLocation();
  const navigation = useNavigate();
  const [isEmployer, setIsEmployer] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);

  const handleSetEmployer = () => {
    setIsEmployer(true);
  };

  const handleNext = () => {
    setIsLoading(true);
    setTimeout(() => {
      // eslint-disable-next-line no-unused-expressions
      setIsLoading(true),
        navigation("/signup/step-two-v2", {
          state: { user: navigate.state },
        });
    }, 1000);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
  };

  return (
    <div className="signUpStepOne">
      <div className="square-left-signup-stepone">
        <div className="title-field-signup-stepone">
          <div className="icon-signup-stepone"></div>
          <div className="title-signup-stepone">Profil Seçimi</div>
          <div className="line-signup-stepone"></div>
        </div>
      </div>
      <div className="square-right-signup-stepone">
        <div className="text-field-signup-stepone">
          <div className="text-one-signup-stepone">Hoş Geldin !</div>
          <div className="text-two-signup-stepone">
            Sana daha iyi bir deneyim sunmamız için lütfen profil tipini seç.
          </div>
        </div>
        <div className="cards-field-signup-stepone">
          <button
            className="card-one"
            onClick={() => {
              setIsEmployer(false);
            }}
            style={{
              borderColor: isEmployer ? "#301934" : "#FFFFFF",
              borderWidth: 2,
              boxShadow: isEmployer ? "none" : "0px 0px 10px #301934",

              backgroundColor: isEmployer ? "#200c37" : "#421c6d",
            }}
          >
            <div className="card-one-image-field-signup-stepone">
              <img src={PeepSittingImage} alt="Img" />
            </div>
            <div className="card-one-text-field-signup-stepone">
              İş Arıyorum
            </div>
          </button>
          <div className="border-signup-stepone"></div>
          <button
            onClick={handleSetEmployer}
            className="card-two"
            style={{
              borderColor: isEmployer ? "#FFFFFF" : "#301934",
              borderWidth: 2,
              boxShadow: isEmployer ? "0px 0px 10px #301934" : "none",
              backgroundColor: isEmployer ? "#421c6d" : "#200c37",
            }}
          >
            <div className="card-two-image-field-signup-stepone">
              <img src={PeepStandingImage} alt="Img" />
            </div>
            <div className="card-two-text-field-signup-stepone">
              İşçi Arıyorum
            </div>
          </button>
          <div
            className="button-field-signup-stepone"
            onClick={() => {
              isEmployer
                ? handleNext()
                : dispatch(registerUser(navigate.state, navigation));
            }}
          >
            <button className="btn-signup-stepone">İleri</button>
          </div>
        </div>
      </div>

      {auth.isLoading ? <LoadingComponent /> : null}
      {isLoading ? <LoadingComponent /> : null}

      <Snackbar
        open={auth.error !== null ? true : false}
        autoHideDuration={10000}
        onClose={handleClose}
        message={auth.error}
      />
    </div>
  );
}
